import { useState, useEffect, MouseEventHandler } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { Dependents, PerformanceData, Platform, Tab } from "./types";
import MultiLineGraph from "./MultiLineGraph";
import CheckedCards from "../../../Components/CheckedCards";
import {
	fetchFilmOverallPerformanceRevenue,
	fetchReachTactics,
} from "../../../Services/films/films";
import DatePicker from "../../../Components/DatePicker";
import BiTab from "../../../Components/BiTab";
import MultiTab from "../../../Components/MultiTab";

const defaultMetrics: Platform[] = [
	{
		title: "Total",
		quantity: undefined,
		key: "total",
		checked: true,
		prefix: "",
	},
	{
		title: "Google",
		quantity: undefined,
		key: "google",
		checked: false,
		prefix: "",
	},
	{
		title: "TikTok",
		quantity: undefined,
		key: "tiktok",
		checked: false,
		prefix: "",
	},
	{
		title: "Facebook",
		quantity: undefined,
		key: "facebook",
		checked: false,
		prefix: "",
	},
	{
		title: "Instagram",
		quantity: undefined,
		key: "instagram",
		checked: false,
		prefix: "",
	},
	{
		title: "YouTube",
		quantity: undefined,
		key: "youtube",
		checked: false,
		prefix: "",
	},
];

const defaultDependents = [
	{
		title: "Impressions",
		active: true,
	},
	{
		title: "Reach",
		active: false,
	},
	{
		title: "Clicks",
		active: false,
	},
	{
		title: "Purchases",
		active: false,
	},
];

export default function ByChannel({
	setGlobalDates,
}: {
	setGlobalDates: Function;
}) {
	const [notCumul, setNotCumul] = useState<boolean>(true);
	const [startDate, setStartDate] = useState<string>("");
	const [endDate, setEndDate] = useState<string>("");
	const [metrics, setMetrics] = useState<Platform[]>(defaultMetrics);
	const [data, setData] = useState<PerformanceData>();
	const [dependents, setDependents] = useState<Tab[]>(defaultDependents);

	const { getAccessTokenSilently } = useAuth0();
	const { filmId } = useParams();

	useEffect(() => {
		async function getReachTactics() {
			const accessToken = await getAccessTokenSilently();
			const startDate = new Date(0);
			const endDate = new Date();
			const fetchedData = await fetchReachTactics(
				accessToken,
				filmId,
				startDate,
				endDate
			);
			setData(fetchedData);
		}
		getReachTactics();
	}, []);

	// Updates the total shown on each metric card according to fetched data and selected dependent
	useEffect(() => {
		if (data && data.totals) {
			const keys = Object.keys(data.totals);
			const metricsCopy = structuredClone(metrics);
			for (let idx in metrics) {
				for (let key of keys) {
					if (metrics[idx].key === key) {
						const activeDependent = getActive(dependents);
						metricsCopy[idx].quantity =
							data.totals[key][activeDependent.toLowerCase()];
					}
				}
			}
			setMetrics(metricsCopy);
		}
	}, [data, dependents]);

	const handleApplyTimeBound = async () => {
		setGlobalDates([new Date(startDate), new Date(endDate)]);
		const accessToken = await getAccessTokenSilently();
		const data = await fetchReachTactics(
			accessToken,
			filmId,
			new Date(startDate),
			new Date(endDate)
		);
		setData(data);
	};

	const getActive = (l: Tab[]) => {
		for (let e of l) {
			if (e.active) {
				return e.title;
			}
		}
		// This should hopefully never be reached since there should always be an active tab
		return "Impressions";
	};

	return (
		<div className="w-full p-8 ">
			<h3 className="mb-4">Marketing Performance</h3>
			<div className="flex flex-row items-center justify-between w-full">
				<BiTab
					bool={notCumul}
					setBool={setNotCumul}
					labels={["DAY-BY-DAY", "CUMULATIVE"]}
				/>
				<MultiTab labels={dependents} setLabels={setDependents} />
				{/* <DatePicker
					startDate={startDate}
					endDate={endDate}
					setStartDate={setStartDate}
					setEndDate={setEndDate}
					onApply={handleApplyTimeBound}
				/> */}
			</div>
			<div className="h-[50vh] bg-shade2 flex flex-col justify-between p-3">
				{data && (
					<>
						<div className="w-full h-[20%]">
							<CheckedCards
								metrics={metrics}
								setMetrics={setMetrics}
							/>
						</div>
						<div className="w-full h-[65%]">
							<MultiLineGraph
								// @ts-ignore
								data={
									notCumul
										? data.perday[
												getActive(
													dependents
												).toLowerCase() as keyof Dependents
										  ]
										: data.cumulative[
												getActive(
													dependents
												).toLowerCase() as keyof Dependents
										  ]
								}
								graphOptions={{}}
								keys={metrics.reduce<string[]>(
									(acc, metric) => {
										if (metric.checked) {
											acc.push(metric.key);
										}
										return acc;
									},
									[]
								)}
							/>
						</div>
					</>
				)}
			</div>
		</div>
	);
}
