export default function BiTab({
	bool,
	setBool,
	labels,
}: {
	bool: boolean;
	setBool: Function;
	labels: string[];
}) {
	const activeStyle =
		" border border-solid border-accent1 bold rounded-sm text-accent1";
	return (
		<div className="text-xs rounded-sm bg-shade2">
			<button
				type="button"
				className={"p-2 " + (bool ? activeStyle : "")}
				onClick={() => {
					setBool(true);
				}}
			>
				{labels[0]}
			</button>
			<button
				type="button"
				className={"p-2 " + (bool ? "" : activeStyle)}
				onClick={() => {
					setBool(false);
				}}
			>
				{labels[1]}
			</button>
		</div>
	);
}
