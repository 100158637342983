import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { RevenueData } from "./types";
import RevenueGraph from "./RevenueGraph";
import { useParams } from "react-router-dom";

import { fetchFilmPerformanceRevenue } from "../../../Services/films/films";
import BiTab from "../../../Components/BiTab";
import DatePicker from "../../../Components/DatePicker";
import StatsType1 from "../../../Components/Stats/StatsType1";
import { Stats } from "fs";

export default function FilmEventRevenueSection() {
	const { filmId } = useParams();

	const [notCumul, setNotCumul] = useState<boolean>(true);
	const [startDate, setStartDate] = useState<string>("");
	const [endDate, setEndDate] = useState<string>("");

	const [revenueData, setRevenueData] = useState<RevenueData>();
	const { getAccessTokenSilently } = useAuth0();

	useEffect(() => {
		async function getFilmPerformanceRevenue() {
			try {
				const accessToken = await getAccessTokenSilently();
				const data = await fetchFilmPerformanceRevenue(
					accessToken,
					filmId
				);
				setRevenueData(data);
			} catch (err) {
				// showBoundary(err);
			}
		}
		getFilmPerformanceRevenue();
	}, [getAccessTokenSilently, filmId, fetchFilmPerformanceRevenue]);

	const handleApplyTimeBound = async () => {
		const accessToken = await getAccessTokenSilently();
		const data = await fetchFilmPerformanceRevenue(
			accessToken,
			filmId,
			new Date(startDate),
			new Date(endDate)
		);
		setRevenueData(data);
	};

	return (
		<div className="w-full p-8 px-12 ">
			<h1 className="text-2xl">Highlights</h1>
			{revenueData && <StatsType1 stats={revenueData.highlights} />}
			<div className="w-full py-8 ">
				<h2 className="font-semibold">Film Tickets and Revenue</h2>
				<div className="flex flex-row items-center justify-between w-full">
					<BiTab
						bool={notCumul}
						setBool={setNotCumul}
						labels={["DAY-BY-DAY", "CUMULATIVE"]}
					/>
					<DatePicker
						startDate={startDate}
						endDate={endDate}
						setStartDate={setStartDate}
						setEndDate={setEndDate}
						onApply={handleApplyTimeBound}
					/>
				</div>
				<div className="h-[50vh] bg-shade2 flex flex-col justify-between p-3">
					{revenueData && (
						<>
							{revenueData.totals.film && (
								<StatsType1 stats={revenueData.totals.film} />
							)}
							<div className="flex-grow">
								<RevenueGraph
									data={
										notCumul
											? revenueData.timeSeries
											: revenueData.cumulTimeSeries
									}
									graphOptions={{}}
									dataKey={"film_revenue"}
								/>
							</div>
						</>
					)}
				</div>
			</div>

			<div className="w-full py-8 ">
				<h2 className="font-semibold">Event Tickets and Revenue</h2>
				<div className="flex flex-row items-center justify-between w-full my-4">
					<BiTab
						bool={notCumul}
						setBool={setNotCumul}
						labels={["DAY-BY-DAY", "CUMULATIVE"]}
					/>
				</div>
				<div className="h-[50vh] bg-shade2 flex flex-col justify-between p-3">
					{revenueData && (
						<>
							{revenueData.totals.event && (
								<StatsType1 stats={revenueData.totals.event} />
							)}
							<div className="flex-grow">
								<RevenueGraph
									data={
										notCumul
											? revenueData.timeSeries
											: revenueData.cumulTimeSeries
									}
									graphOptions={{}}
									dataKey={"event_revenue"}
								/>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
}
