import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from "react-router-dom";
// console.log(process.env.NODE_ENV);
// console.log((process.env.REACT_APP_API_URL || "") + "/tunnel");
// console.log("OI PAY ATTENTION TO ME");

Sentry.init({
	dsn: "https://250da50f6f2db70d7e9096f711a2c18f@o4507696758784000.ingest.de.sentry.io/4507696770973776",
	tunnel: process.env.REACT_APP_API_URL + "/tunnel",
	integrations: [
		// See docs for support of different versions of variation of react router
		// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		}),
		// Sentry.browserTracingIntegration(), // I think the react router tracing integration does tracing
		Sentry.browserProfilingIntegration(),
		Sentry.replayIntegration(),
	],
	environment: process.env.NODE_ENV,
	debug: process.env.NODE_ENV === "development",

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for tracing.
	tracesSampleRate: 1.0,

	// Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
	tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
	// Profiling
	profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate

	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});
