import React, { useState, useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
<link rel="stylesheet" type="text/css" href="../index.css"></link>;

interface ToolSubMessage {
	type: string;
	text?: string;
}

interface MessageContent {
	type: string;
	text?: string;
	img?: string;
	id?: string;
	name?: string;
	input?: {};
	tool_use_id?: string;
	is_error?: boolean;
	content?: ToolSubMessage;
}

type SendMessage = {
	action: string;
	messages: string;
};

interface Chat {
	role: string;
	content: MessageContent[];
}

const Chatbot: React.FC = () => {
	const [userInput, setUserInput] = useState<string>("");
	const [chatHistory, setChatHistory] = useState<Chat[]>([]);
	const [messageHistory, setMessageHistory] = useState<Chat[]>([]);
	const chatContainerRef = useRef<HTMLDivElement>(null);
	const { getAccessTokenSilently } = useAuth0();
	const [ws, setWs] = useState<WebSocket | null>(null);
	const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

	useEffect(() => {
		const wsUrl = 'https://hcpkvcqqqc.execute-api.us-east-1.amazonaws.com/production/';
		let socket: WebSocket;

		const connectWebSocket = () => {
			socket = new WebSocket(wsUrl);

			socket.onopen = () => {
				console.log('Connected to WebSocket');
				setIsButtonDisabled(false);
			};

			socket.onmessage = (event) => {
				try {
					const socketMessage = event.data.toString();
					const message_details = JSON.parse(socketMessage);
					console.log(message_details.message);
					if (message_details.message === "Endpoint request timed out") {
						console.log("Endpoint timeout");
					} else {
						const message_content = message_details.message.content;
						const received_role = message_details.message.role;
						if (message_content[0].type === "disconnect") {
							console.log("Final message")
							setIsButtonDisabled(false); // Re-enable the send button
						}
						else if (message_content[0].type !== "disconnect") {
							setMessageHistory((prevMessageHistory) => [
								...prevMessageHistory,
								{ role: received_role.toString(), content: message_content },
						]);
						if (message_content[0].type === 'text') {
							setChatHistory((prevChatHistory) => [
								...prevChatHistory,
								{ role: received_role.toString(), content: message_content },
						]);
						}
						else if (message_content[0].type === 'img') {
							setChatHistory((prevChatHistory) => [
								...prevChatHistory,
								{ role: received_role.toString(), content: message_content },
						]);
						}
					}
					}
				} catch (e) {
					console.log('Error in getting response');
				}
			};

			socket.onerror = (error) => {
				console.error('WebSocket error:', error);
				socket.close();
			};

			socket.onclose = () => {
				console.log('WebSocket closed.');
			};

			setWs(socket);
		};

		connectWebSocket();

		return () => {
			if (socket) {
				socket.close();
				console.log('WebSocket disconnected.');
			}
		};
	}, []);

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setUserInput(event.target.value);
	};

	function replacer(key: any, value: any) {
		return value.replace(/[^\w\s]/gi, '');
	  }

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const promptInput = userInput;
		const newMessageHistory = [
			...messageHistory,
			{ role: "user", content: [{ type: 'text', text: promptInput }] }
		];
		setMessageHistory(newMessageHistory);

		const newChatHistory = [
			...chatHistory,
			{ role: "user", content: [{ type: 'text', text: promptInput }] },
			{ role: "assistant", content: [{ type: 'text', text: "Let's start by analyzing our data." }] }
		];
		setChatHistory(newChatHistory);

		if (ws && ws.readyState === WebSocket.OPEN) {
			try {
				const cleanMessageHistory: Chat[] = [];

				newMessageHistory.forEach(jsonString => {
					if (jsonString.content[0].type != 'img'){
						cleanMessageHistory.push(jsonString)
					}	
				  });

				const message: SendMessage = {
					action: 'sendMessage',
					messages: JSON.stringify(cleanMessageHistory)
				};
				// Calculate the memory size in bytes
				ws.send(JSON.stringify(message));
				console.log('Sent message:', message);
				setIsButtonDisabled(true); // Disable the send button
			} catch (e) {
				console.log('Error in sending message');
			}
		}

		setUserInput("");
	};

	return (
		<div className="flex flex-col h-[80vh]">
			<div className="flex-grow overflow-y-auto hide-scrollbar p-4 min-h-[calc(100vh - 400px)]" ref={chatContainerRef}>
				{chatHistory.map((chat, index) => {
					switch (chat.role) {
						case "user":
							return <UserMessage key={index} content={chat.content} />;
						case "assistant":
							return <BotMessage key={index} content={chat.content} />;
						default:
							return null;
					}
				})}
			</div>
			<form
				className="flex items-center justify-between p-4 mt-2 rounded-lg h-14 bg-black-200"
				onSubmit={handleSubmit}
			>
				<input
					className="flex-1 p-2 mr-5 text-white bg-black border-2 border-white rounded-lg"
					type="text"
					placeholder="Ask JoltER..."
					value={userInput}
					onChange={handleInputChange}
				/>
				<button
					className="flex flex-shrink-0 px-4 py-2 text-white border-2 border-white rounded-lg shadow-md bg-black-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
					type="submit"
					disabled={isButtonDisabled}
				>
					{isButtonDisabled ? "Analyzing..." : "Send"}
				</button>
			</form>
		</div>
	);
};

const formatMessageContent = (content: MessageContent[]) => {
	const regex1 = /\n<graphCode>[\s\S]*?<\/graphCode>\n/g;
	const regex2 = /<thinking>[\s\S]*?<\/thinking>/g;
	return content.map((msg, index) => (
		<React.Fragment key={index}>
			{msg.type === 'text' && msg.text?.replace(regex1, '').replace(regex2, '').replace("\n\n\n", '\n').split("\n").map((line, idx) => (
				<React.Fragment key={idx}>
					{line}
					<br />
				</React.Fragment>
			))}
			{msg.type === 'img' && (
				<div className="flex items-center justify-center">
					<img src={`data:image/png;base64,${msg.img}`} alt="message content" />
				</div>
			)}
		</React.Fragment>
	));
};

const UserMessage: React.FC<{ content: MessageContent[] }> = ({ content }) => {
	return (
		<div className="mb-4 text-lg text-zinc-200">
			<strong className="text-accent1">User:</strong>
			<br />
			{formatMessageContent(content)}
		</div>
	);
};

const BotMessage: React.FC<{ content: MessageContent[] }> = ({ content }) => {
	return (
		<div className="mb-4 text-lg text-zinc-200">
			<strong className="text-accent1">Jolt:</strong>
			<br />
			{formatMessageContent(content)}
		</div>
	);
};

export default Chatbot;
