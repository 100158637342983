import {
	createBrowserRouter,
	createRoutesFromElements,
	RouterProvider,
	Route,
} from "react-router-dom";

import AuthLayout from "./Auth/AuthLayout";
import AuthenticationGuard from "./Auth/AuthenticationGuard";
import OuterNavigationLayout from "./Components/Nav/OuterNavigationLayout";
import InnerNavigationLayout from "./Components/Nav/InnerNavigationLayout";

import ChatbotPage from "./Pages/Admin/Chatbot/ChatbotPage";
import FilmPerformance from "./Pages/Film/FilmPerformance/FilmPerformance";

import PermissionsPage from "./Pages/Admin/Permissions/PermissionsPage";

import FilmsPage from "./Pages/Films/FilmsPage";
import OverallPerformance from "./Pages/Film/OverallPerformance/OverallPerformance";

import SendToHomePage from "./Components/SendToHomePage";

import AdminClearance from "./Auth/AdminClearance";
import ErrorBoundaryLayout from "./Components/ErrorBoundaryLayout";
import WatchPerformance from "./Pages/Film/WatchPerformance/WatchPerformance";
import MarketingSpend from "./Pages/Film/MarketingSpend/MarketingSpend";
import ReachTactics from "./Pages/Film/ReachTactics/ReachTactics";

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path="/" element={<ErrorBoundaryLayout />}>
			<Route element={<AuthLayout />} path="/">
				<Route element={<AuthenticationGuard />}>
					<Route element={<OuterNavigationLayout />}>
						<Route
							index={true}
							path="/"
							element={<SendToHomePage />}
						/>
						<Route path="films" element={<FilmsPage />} />
						<Route path="/" element={<AdminClearance />}>
							<Route
								path="permissions"
								element={<PermissionsPage />}
							/>
							<Route path="JoltER" element={<ChatbotPage />} />
						</Route>
					</Route>
					<Route element={<InnerNavigationLayout />} path="/">
						<Route
							path="films/:filmId"
							element={<OverallPerformance />}
						/>
						<Route
							path="films/:filmId/marketing"
							element={<MarketingSpend />}
						/>
						<Route
							path="films/:filmId/reach"
							element={<ReachTactics />}
						/>
						<Route
							path="films/:filmId/filmperformance"
							element={<FilmPerformance />}
						/>
						<Route
							path="films/:filmId/watchperformance"
							element={<WatchPerformance />}
						/>
					</Route>
				</Route>
			</Route>
		</Route>
	)
);

function App() {
	return (
		<div className="font-sans text-text_light1 bg-dark">
			<RouterProvider router={router} />
		</div>
	);
}

export default App;

// This seems weird, but just by putting this in code tailwind will know to
// include the classes we want - it has to see raw unbroken strings of
// classes - it doesn't have to be in top-level file either, could be in a small component
const lol = [
	"h-1",
	"h-2",
	"h-3",
	"h-4",
	"h-5",
	"h-6",
	"h-7",
	"h-8",
	"h-9",
	"h-10",
	"h-11",
	"h-12",
	"h-14",
	"h-16",
	"h-20",
	"h-24",
	"h-28",
	"h-32",
	"h-36",
	"h-40",
	"h-44",
	"h-48",
	"h-52",
	"h-56",
	"h-60",
	"h-64",
	"h-72",
	"h-80",
	"h-96",
	"w-1",
	"w-2",
	"w-3",
	"w-4",
	"w-5",
	"w-6",
	"w-7",
	"w-8",
	"w-9",
	"w-10",
	"w-11",
	"w-12",
	"w-14",
	"w-16",
	"w-20",
	"w-24",
	"w-28",
	"w-32",
	"w-36",
	"w-40",
	"w-44",
	"w-48",
	"w-52",
	"w-56",
	"w-60",
	"w-64",
	"w-72",
	"w-80",
	"w-96",
];
