import {
	ResponsiveContainer,
	BarChart,
	LineChart,
	Line,
	Bar,
	XAxis,
	YAxis,
	Label,
	CartesianGrid,
	Tooltip,
	PieChart,
	Pie,
	Sector,
	Cell,
} from "recharts";
import { GraphBundle, graphOptions } from "../../types/types";

// Some code for the pie chart
const COLORS = [
	"#0088FE",
	"#00C49F",
	"#FFBB28",
	"#FF8042",
	"#7122c3",
	"#ef2535",
];

interface labelCustomiser {
	[key: string]: number;
}

const RADIAN = Math.PI / 180;
const renderCustomizedLabelInside = (props: labelCustomiser) => {
	const { cx, cy, midAngle, innerRadius, outerRadius, percent, index, key } =
		props;
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	return (
		<text
			x={x}
			y={y}
			fill="white"
			textAnchor={x > cx ? "start" : "end"}
			dominantBaseline="central"
		>
			{`${(percent * 100).toFixed(0)} ${key}%`}
		</text>
	);
};

const renderCustomizedLabel = (props: labelCustomiser) => {
	const {
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		percent,
		index,
		key,
		fill,
	} = props;
	const radius = outerRadius + (outerRadius - innerRadius) * 0.2;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);
	const lx = cx + outerRadius * Math.cos(-midAngle * RADIAN);
	const ly = cy + outerRadius * Math.sin(-midAngle * RADIAN);
	const lex = lx + (x - lx) * 0.7;
	const ley = ly + (y - ly) * 0.7;
	// const textsize: number = Math.floor(outerRadius / 4);
	// const textSize: string = String(textsize);
	return (
		// <div className="text-black">
		//   {key}
		// </div>
		<g>
			<path
				d={`M${lx},${ly}L${lex},${ley}`}
				stroke={String(fill)}
				fill={String(fill)}
			/>
			<text
				style={{ fontSize: "0.8rem" }}
				fill={String(fill)} // if you want black text, just change this to "black"
				x={x}
				y={y}
				textAnchor={x > cx ? "start" : "end"}
				dominantBaseline="central"
				//  lengthAdjust="spacingAndGlyphs"
				//  textLength="15%"
			>
				{`${key}`}
			</text>
		</g>
	);
};

// Colour back up

let colours = [
	"#0088FE",
	"#00C49F",
	"#FFBB28",
	"#FF8042",
	"red",
	"pink",
	"#17A589",
	"#F39C12",
	"#7D3C98",
	"#2E4053",
	"#2e4053",
	"#f39c12",
	"#17a589",
	"#2874a6",
	"#DAF7A6",
	"#40E0D0",
	"#DE3163",
	"#CCCCFF",
	"#6495ED",
	"#33FF89",
];

export default function PieGraph({ data, graphOptions }: GraphBundle) {
	return (
		<ResponsiveContainer width="100%" height="100%">
			<PieChart width={400} height={400}>
				<Pie
					data={data}
					cx="50%"
					cy="50%"
					labelLine={false}
					label={renderCustomizedLabel}
					outerRadius="50%"
					fill="#8884d8"
					dataKey="value"
					nameKey="key"
					isAnimationActive={true}
				>
					{data.map(({ key, value }, index) => (
						<Cell
							key={`cell-${key}`}
							fill={
								graphOptions.pieColours
									? graphOptions.pieColours[index] // @ts-ignore
									: colours[value % colours.length]
							}
						/> // In case you want to revert
						// This was the original fill: COLORS[value % COLORS.length]
					))}
				</Pie>
				<Tooltip />
			</PieChart>
		</ResponsiveContainer>
	);
}
