import { RevenueMetric } from "../Pages/Film/OverallPerformance/types";

export default function CheckedCards({
	metrics,
	setMetrics,
}: {
	metrics: RevenueMetric[];
	setMetrics: Function;
}) {
	const handleClick = (title: string) => {
		const updateItems = metrics.map((metric) => {
			return metric.title === title
				? { ...metric, checked: !metric.checked }
				: metric;
		});
		setMetrics(updateItems);
	};

	return (
		<div className="flex flex-row">
			{metrics.map((metric) => {
				return (
					<label
						htmlFor={metric.title}
						className={
							"p-3 m-3 text-black rounded-lg " +
							(metric.quantity !== null && metric.quantity !== undefined ? "bg-white" : "bg-[#8c8d94]")
						}
					>
						<div className="text-xs">{metric.title}</div>
						<div className="flex flex-row justify-between text-lg">
							<div className="pr-3">
								{(metric.quantity !== null && metric.quantity !== undefined ? metric.prefix + Number(metric.quantity).toLocaleString() : "No Data")}
							</div>
							<input
								id={metric.title}
								type="checkbox"
								value={metric.title}
								checked={metric.checked}
								onClick={() => {
									handleClick(metric.title);
								}}
								disabled={!(metric.quantity == 0) && !metric.quantity}
								style={{
									visibility: metric.quantity ||metric.quantity==0
										? "visible"
										: "hidden",
								}}
							/>
						</div>
					</label>
				);
			})}
		</div>
	);
}
