import ReactLoading from "react-loading";

export const PageLoader = () => {
	return (
		<div className="w-full h-[90vh] flex flex-row justify-center items-center">
			<ReactLoading
				type={"spokes"}
				color={"#FFFFFF"}
				height={"5%"}
				width={"5%"}
			/>
		</div>
	);
};
