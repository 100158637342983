import { Tab } from "../Pages/Film/ReachTactics/types";

export default function MultiTab({
	setLabels,
	labels,
	width = undefined,
}: {
	setLabels: Function;
	labels: Tab[];
	width?: number;
}) {
	const updateActive = (labelTitle: string) => {
		const labelsCopy = structuredClone(labels);
		for (let label of labelsCopy) {
			if (label.title === labelTitle) {
				label.active = true;
			} else {
				label.active = false;
			}
		}
		setLabels(labelsCopy);
	};

	const activeStyle =
		" border border-solid border-accent1 bold rounded-sm text-accent1";

	return (
		<div className="flex flex-row justify-start text-xs rounded-sm bg-shade2">
			{labels &&
				labels.map((label) => {
					return (
						<button
							type="button"
							className={
								"p-2 " + (label.active ? activeStyle : "")
							}
							style={{
								width: width ? `$${width}px` : "auto",
							}}
							onClick={() => {
								updateActive(label.title);
							}}
						>
							{label.title}
						</button>
					);
				})}
		</div>
	);
}
