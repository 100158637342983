import { ReactNode, SyntheticEvent, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import dedent from "dedent-js";

import {
	fetchFilms,
	fetchUserFilms,
	fetchUserRoles,
	fetchUserRolesPermissionsFilms,
	updateUser,
	fetchUsers,
} from "../../../Services/permissions";

import {
	FilmBasic,
	Permission,
	PermissionsPerRole,
	Role,
	UserBasic,
} from "./types";
import ReactLoading from "react-loading";
import UserSection from "./UserSection";

import SelectContainer from "./SelectContainer";

export default function PermissionsPage() {
	const { user: originalUser } = useAuth0();

	const [userList, setUserList] = useState<UserBasic[]>();
	const [userId, setUserId] = useState<string>();
	const [noRoles, setNoRoles] = useState<boolean>();
	const [roles, setRoles] = useState<Role[]>();
	const [permissions, setPermissions] = useState<Permission[]>();
	const [permissionsPerRole, setPermissionsPerRole] =
		useState<PermissionsPerRole>();
	const [filmsList, setFilmsList] = useState<FilmBasic[]>();

	const { getAccessTokenSilently } = useAuth0();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		setIsLoading(true);
		async function getUsersList() {
			const accessToken = await getAccessTokenSilently();
			const response: UserBasic[] = await fetchUsers(accessToken);
			setUserList(response);
		}
		getUsersList();
		setIsLoading(false);
	}, []);

	useEffect(() => {
		let noRolesCheck = true;
		if (roles) {
			for (let role of roles) {
				if (role.checked) {
					noRolesCheck = false;
				}
			}
			setNoRoles(noRolesCheck);
		}
	}, [roles]);

	async function handleUserSelect(event: SyntheticEvent) {
		setIsLoading(true);
		const target = event.target as HTMLInputElement;
		const user_id = target.value;
		const accessToken = await getAccessTokenSilently();
		const { films, roles, permissions, permissionsPerRole } =
			await fetchUserRolesPermissionsFilms(accessToken, user_id);
		setUserId(user_id);
		setRoles(roles);
		setPermissions(permissions);
		setPermissionsPerRole(permissionsPerRole);
		setFilmsList(films);
		setIsLoading(false);
	}

	async function involvedSetRoles(roles: Role[]) {
		setRoles(roles);
		let permissions_to_check_and_disable = [];
		for (let role of roles) {
			if (role.checked && permissionsPerRole) {
				permissions_to_check_and_disable.push(
					...permissionsPerRole[role.id]
				);
			}
		}
		permissions_to_check_and_disable = [
			...new Set(permissions_to_check_and_disable),
		];
		const new_permissions =
			permissions &&
			permissions.map((permission) => {
				if (permissions_to_check_and_disable.includes(permission.id)) {
					permission.checked = true;
					permission.disabled = true;
				} else {
					permission.disabled = false;
				}
				return permission;
			});
		setPermissions(new_permissions);
	}

	function complicateSetPermissions(itemsList: any) {
		const disabledPermissions = permissions
			? permissions.filter((item) => item.disabled)
			: [];
		const final = itemsList.concat(disabledPermissions);
		setPermissions(final);
	}

	async function handleSubmit(event: SyntheticEvent) {
		event.preventDefault();
		// Checking if an admin is about to un-admin themselves
		if (!originalUser) {
			console.log(
				"Error - cannot find original user. Permissions changing aborted in case of error."
			);
			return;
		}
		if (originalUser.sub === userId) {
			alert(
				dedent(
					`You are potentially about to change your own permissions as a user.
				 This is not recommended since only admins can view this 
				 page and therefore you can only be removing permissions from 
				 yourself. To proceed, ask another admin to change your 
				 permissions.`
				)
			);
			return;
		}

		const accessToken = await getAccessTokenSilently();
		updateUser(accessToken, userId, roles, permissions, filmsList);
		alert(`User updated successfully`);
	}

	return (
		<div className="flex flex-col w-full ">
			<div className="flex flex-col items-center w-full h-full">
				<div className="flex flex-col w-2/3 max-w-[800px]">
					<h1 className="mt-8 mb-8 ml-[20px]">Permissions</h1>
					<div className="my-4">
						{userList && (
							<UserSection
								userList={userList}
								handleUserSelect={handleUserSelect}
							/>
						)}
					</div>
					{!userId ? (
						<div></div>
					) : isLoading ? (
						<div className="flex flex-row items-center justify-center w-full mt-6">
							<ReactLoading
								type={"spokes"}
								color={"#FFFFFF"}
								height={"5%"}
								width={"5%"}
							/>
						</div>
					) : (
						<form
							className="flex flex-col items-center p-3 my-4 border border-gray-500 border-solid rounded-lg"
							onSubmit={handleSubmit}
						>
							<div className="w-[90%] flex flex-col">
								<fieldset className="flex flex-col w-full">
									<legend className="pb-3">
										<h2>User Roles</h2>
									</legend>
									<div className="w-[60%] min-w-[300px] self-center">
										<div className="sticky top-0 flex flex-row justify-between mb-3 border-b-2 border-solid bg-dark border-accent1">
											<div className="flex flex-row w-full">
												<div className="w-5/12">
													Role
												</div>
												<div>Description</div>
											</div>
										</div>
										<SelectContainer
											type="radio"
											items={roles}
											setItems={involvedSetRoles}
											// @ts-ignore
											LabelElement={RoleLabel}
											className="border-t-[1px] border-shade1 border-solid"
										/>
									</div>
								</fieldset>

								<hr className="self-center w-full my-8 border-gray-500" />
								<fieldset className="flex flex-col items-start mb-8">
									<legend className="pb-3">
										<h2>User Permissions</h2>
									</legend>
									<h3 className="border-b-[1px] border-accent1 border-solid mb-4">
										Permissions automatically assigned
										through role
									</h3>
									{permissions &&
									permissions.filter(
										(permission) => permission.disabled
									).length > 0 ? (
										<div className="flex flex-col items-center w-[80%] self-center border-t-[1px] border-shade1 border-solid  ">
											<SelectContainer
												type="checkbox"
												// @ts-ignore
												items={
													permissions &&
													permissions.filter(
														(permission) =>
															permission.disabled
													)
												}
												setItems={setPermissions}
												cols={[
													"Permission",
													"Description",
												]}
												// @ts-ignore
												LabelElement={PermissionLabel}
												// onHover={permissionsHoverHandler}
											/>
										</div>
									) : (
										<div className="h-[10vh] text-center w-full flex flex-row justify-center items-center">
											<div>
												No permissions automatically set
												by roles
											</div>
										</div>
									)}
									<h3 className="border-b-[1px] border-accent1 border-solid inline-block my-4 ">
										Permissions free for assignment
									</h3>
									{permissions &&
									permissions.filter(
										(permission) => !permission.disabled
									).length > 0 ? (
										<>
											<div className="flex flex-col items-center self-center w-4/5 ">
												<div className="flex flex-row w-full border-b-2 border-solid border-b-accent1">
													<div className="w-5/12">
														Permission
													</div>
													<div>Description</div>
												</div>
												<SelectContainer
													type="checkbox"
													// @ts-ignore
													items={
														permissions &&
														permissions.filter(
															(permission) =>
																!permission.disabled
														)
													}
													setItems={
														complicateSetPermissions
													}
													// @ts-ignore
													LabelElement={
														PermissionLabel
													}
													className=""
													// onHover={permissionsHoverHandler}
												/>
											</div>
										</>
									) : (
										<div className="h-[10vh] text-center w-full flex flex-row justify-center items-center">
											<div>
												All possible permissions already
												assigned through roles
											</div>
										</div>
									)}
								</fieldset>
								<hr className="self-center w-full my-8 border-gray-500" />
								{noRoles && (
									<fieldset className="flex flex-col">
										<legend className="pb-3">
											<h2>
												Films user is permitted to see
											</h2>
											<div className="text-sm italic">
												Note if a user has admin or
												internal roles they can view all
												films
											</div>
										</legend>
										<div className="flex flex-col items-center border-t-[1px] h-[20vh] border-shade1 border-solid w-4/5 self-center overflow-y-scroll scrollbar">
											<SelectContainer
												type="checkbox"
												items={filmsList}
												setItems={setFilmsList}
												// Note that typing for this specific situation is complicated
												// (maybe this isn't particularly well structured or written)
												// @ts-ignore
												LabelElement={FilmLabel}
											/>
										</div>
									</fieldset>
								)}
								<button
									className="p-3 mt-6 rounded-lg bg-accent1 w-[200px] self-center"
									type="submit"
								>
									Update user permissions
								</button>
							</div>
						</form>
					)}
				</div>
			</div>
		</div>
	);
}

function UserLabel({ item: user }: { item: UserBasic }) {
	return (
		<label
			className="flex flex-row items-center ml-8 "
			htmlFor={user.user_id}
		>
			<div className="w-[250px]">Name: {user.name}</div>
			<div>Email: {user.email}</div>
		</label>
	);
}

function RoleLabel({
	item: role,
	children,
}: {
	item: Role;
	children: ReactNode;
}) {
	return (
		<label
			className="flex flex-row items-center justify-between w-full ml-3"
			htmlFor={"film_id"}
		>
			<div className="w-5/12 shrink-0">{role.name}</div>
			<div>{role.description}</div>
			{children}
		</label>
	);
}

function FilmLabel({
	item: film,
	children,
}: {
	item: FilmBasic;
	children: ReactNode;
}) {
	return (
		<label
			className="flex flex-row items-center justify-between w-full ml-3"
			htmlFor={"film_id"}
		>
			<div>{film.title}</div>
			{children}
		</label>
	);
}

function PermissionLabel({
	item: permission,
	children,
}: {
	item: Permission;
	children: ReactNode;
}) {
	return (
		<label
			className="flex flex-row items-center w-full ml-3"
			htmlFor={"film_id"}
		>
			<div className="w-5/12 my-2 shrink-0 ">
				<div className="inline px-2 font-mono border border-solid rounded-lg border-accent1">
					{permission.id}
				</div>
			</div>
			<div className="my-2">{permission.description}</div>
			{children}
		</label>
	);
}
