export function capitalizeWords(str: string) {
	// Convert the entire string to lowercase
	str = str.toLowerCase();

	// Split the string into an array of words
	const words = str.split(" ");

	// Capitalize the first letter of each word
	const capitalizedWords = words.map((word) => {
		return word.charAt(0).toUpperCase() + word.slice(1);
	});

	// Join the words back into a single string
	return capitalizedWords.join(" ");
}

export function stringToColor(inputString: string, colorsArray: string[]) {
	// Simple hash function
	let hash = 0;
	for (let i = 0; i < inputString.length; i++) {
		hash = inputString.charCodeAt(i) + ((hash << 5) - hash);
	}

	// Ensure the hash is a positive integer and use modulo to fit into the color array length
	const index = Math.abs(hash) % colorsArray.length;

	return colorsArray[index];
}

export function uniqueStringArrayToColours(keys: string[], colors: string[]) {
	// Simple hash function to generate a hash code for a given key
	function hashCode(key: string) {
		let hash = 0;
		for (let i = 0; i < key.length; i++) {
			const char = key.charCodeAt(i);
			hash = (hash << 5) - hash + char;
			hash |= 0; // Convert to 32bit integer
		}
		return hash;
	}

	// Create a map to store the key-color pairs and a set to track used colors
	const colorMap = new Map();
	let usedColors = new Set();

	keys.forEach((key) => {
		// Generate the hash code and map it to an index in the colors array
		const hash = hashCode(key);
		let colorIndex = Math.abs(hash) % colors.length;

		// Find the next available color if there's a collision
		while (usedColors.has(colorIndex)) {
			colorIndex = (colorIndex + 1) % colors.length;
		}

		// Assign the color to the key and mark it as used
		colorMap.set(key, colors[colorIndex]);
		usedColors.add(colorIndex);
		if (usedColors.size === colors.length) {
			usedColors = new Set();
		}
	});

	// Convert the map to an object or return the map as is
	// const result = {};
	// for (let [key, color] of colorMap.entries()) {
	// 	result[key] = color;
	// }

	return colorMap;
}

// Array of weekday names
export const weekdays = [
	"Sunday",
	"Monday",
	"Tuesday",
	"Wednesday",
	"Thursday",
	"Friday",
	"Saturday",
];

// Array of month names
export const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];
