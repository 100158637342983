import { useState, useEffect } from "react";
import { NavLink, Navigate } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";

export default function OuterNavigation() {
	const { user, logout, isAuthenticated, isLoading } = useAuth0();

	function logoutHandler() {
		console.log("Logging out");
		logout({
			logoutParams: {
				returnTo: window.location.origin,
			},
		});
	}

	const navExternal = [
		{ path: "/films", title: "Films" }, // Also need links to individual films of form films/film/:filmid on this page
	];

	const navInternal = [
		{ path: "/films", title: "Films" },
		{ path: "/JoltER", title: "JoltER" },
	];

	const navAdmin = [
		{ path: "/films", title: "Films" },
		// { path: "/allfilms", title: "All Films" },

		{ path: "/JoltER", title: "JoltER" },
		{ path: "/permissions", title: "Permissions" },
	];

	const [nav, setNav] = useState(navExternal);
	const [settings, setSettings] = useState("/external/settings");

	useEffect(() => {
		const role_property = process.env.REACT_APP_AUTH0_AUDIENCE + "/roles";
		const roles = user && user[role_property];
		if (roles === undefined) {
			return;
		}
		if (roles.includes("Admin")) {
			setNav(navAdmin);
		} else if (roles.includes("Internal")) {
			setNav(navInternal);
		}

		if (roles.includes("Internal")) {
			setSettings("/internal/settings");
		} else if (roles.includes("Admin")) {
			setSettings("/admin/settings");
		}
	}, [user]);

	if (isLoading) {
		return <></>;
	}
	return (
		<>
			<div className="h-full w-full flex flex-col justify-between bg-dark border-r text-right text-2xl py-2.5">
				<div className="h-60% flex flex-col items-end justify-between">
					<img
						className="w-[90%] rounded-xl pt-5"
						src={`${process.env.PUBLIC_URL}/Images/logo_dark.png`}
						alt="logo"
					/>
					<div className="flex flex-col p-8">
						{nav.map((item) => {
							return (
								<SideBarItem
									path={item.path}
									title={item.title}
									key={item.title}
								/>
							);
						})}
					</div>
				</div>
				<div className="flex flex-col gap-2 p-8 ">
					{/* <SideBarItem path="" title="Settings" /> */}
					<button className="text-right" onClick={logoutHandler}>
						Logout
					</button>
				</div>
			</div>
		</>
	);
}

function SideBarItem({ path, title }: any) {
	return (
		<NavLink
			to={path}
			className={({ isActive }) =>
				isActive ? "text-accent1 font-semibold" : ""
			}
		>
			<h2 className="py-2 text-2xl">{title}</h2>
		</NavLink>
	);
}
