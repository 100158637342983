import { Navigate, Outlet, useNavigate } from "react-router-dom";
import OuterNavigation from "./OuterNavigation";
import { useState, useEffect, BaseSyntheticEvent, useReducer } from "react";
import { join } from "path";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { PageLoader } from "../PageLoader";

export default function OuterNavigationLayout() {
	const Component = withAuthenticationRequired(
		() => {
			return (
				<div className="flex w-screen h-screen">
					{/* Navigation Panel */}
					<div className="w-[200px] h-full fixed">
						<OuterNavigation />
					</div>
					{/* Content Area */}
					<div className="ml-[200px] w-[calc(100vw-200px)] h-full overflow-auto">
						<Outlet />
					</div>
				</div>
			);
		},
		{
			onRedirecting: () => {
				return (
					<div className="page-layout">
						<PageLoader />
					</div>
				);
			},
		}
	);

	return <Component />;
}
