import { FilmBasic, Role, Permission } from "../Pages/Admin/Permissions/types";
import { authGet, authPost } from "../Utils/fetches";

export async function fetchFilms(accessToken: string) {
	const path = `/permissionsData/films`;
	return await authGet(accessToken, path);
}

export async function fetchUsers(accessToken: string) {
	const path = `/permissionsData/users`;
	return authGet(accessToken, path);
}

export async function fetchUserRolesPermissionsFilms(
	accessToken: string,
	userId: string
) {
	const path = `/permissionsData/rolesandfilms`;
	const body = { user_id: `${userId}` };
	const res = await authPost(accessToken, path, body);
	return res;
}

export async function fetchUserRoles(accessToken: string, userId: string) {
	const path = `/permissionsData/roles`;
	const body = { user_id: `${userId}` };
	const res = await authPost(accessToken, path, body);
	return res;
}

export async function fetchUserFilms(accessToken: string, userId: string) {
	const path = `/permissionsData/films`;
	const body = { user_id: `${userId}` };
	const res = await authPost(accessToken, path, body);
	return res;
}

export async function updateUser(
	accessToken: string,
	userId: string | undefined,
	roles: Role[] | undefined,
	permissions: Permission[] | undefined,
	films: FilmBasic[] | undefined
) {
	if (!userId || !roles || !films) {
		console.log("No user was updated because no user was selected");
		return;
	}
	const film_ids: string[] = [];
	films.forEach((element) => {
		if (element.checked) {
			film_ids.push(element.id);
		}
	});

	const role_ids: string[] = [];
	roles.forEach((element) => {
		if (element.checked) {
			role_ids.push(element.id);
		}
	});
	const body = {
		user_id: `${userId}`,
		role_ids,
		permissions,
		film_ids,
	};

	const path = `/permissionsData/updateUser`;
	const res = await authPost(accessToken, path, body);
	return res;
}

// export async function postUserRoles(
// 	accessToken: string,
// 	userId: string,
// 	role_ids: string
// ) {
// 	const domain = process.env.REACT_APP_API_URL || "";
// 	const response = await fetch(domain + `/permissionsData/updateRoles`, {
// 		method: "POST",
// 		headers: {
// 			Authorization: `Bearer ${accessToken}`,
// 			"Content-Type": "application/json",
// 		},
// 		body: JSON.stringify({
// 			user_id: `${userId}`,
// 			role_ids,
// 		}),
// 	});
// 	const res = await response.json();
// 	return res;
// }

// export async function postUserFilms(
// 	accessToken: string,
// 	userId: string,
// 	filmIds: string[]
// ) {
// 	const domain = process.env.REACT_APP_API_URL || "";
// 	const response = await fetch(domain + `/permissionsData/updateRoles`, {
// 		method: "POST",
// 		headers: {
// 			Authorization: `Bearer ${accessToken}`,
// 			"Content-Type": "application/json",
// 		},
// 		body: JSON.stringify({
// 			user_id: `${userId}`,
// 			film_ids: filmIds,
// 		}),
// 	});
// 	const res = await response.json();
// 	return res;
// }
