import DOMPurify from "dompurify";

export function Purify({ htmlString }: { htmlString: string }) {
	const sanitizedHTML = DOMPurify.sanitize(htmlString);
	const styleRemoved =  sanitizedHTML.replace(/style="[^"]*"/g, '');
	const gapRemoved = styleRemoved.replace("<p><br></p>", "")
	return <div dangerouslySetInnerHTML={{ __html: gapRemoved }} />;
}

export function FlawedClean({ htmlString }: { htmlString: string }) {
	const cleanText = htmlString.replace(/<\/*[^>]+(>|$)/g, "");
	return cleanText;
}
