import { PropsWithChildren, useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export default function AdminClearance() {
	const { user } = useAuth0();
	const role_property = process.env.REACT_APP_AUTH0_AUDIENCE + "/roles";
	const [roles, setRoles] = useState<string[] | undefined>(
		user && user[role_property]
	);

	useEffect(() => {
		const role_property = process.env.REACT_APP_AUTH0_AUDIENCE + "/roles";
		const roles = user && user[role_property];
		setRoles(roles);
	}, [user]);

	if (roles && roles.includes("Admin")) {
		return <Outlet />;
	} else {
		return <Navigate to="/" />;
	}
}
