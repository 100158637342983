import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { fetchAudienceData } from "../../../Services/films/films";
import PieGraph from "./PieGraph";
import { AudienceData, Tab } from "./types";
import Explicit from "../MarketingSpend/Explicit";
import HorizBarGraph from "../../../Components/Graphs/HorizBarGraph";
import MultiTab from "../../../Components/MultiTab";

const defaultDemoOptions: (Tab & { key: keyof AudienceData })[] = [
	{
		title: "Site Visitors",
		active: true,
		key: "siteVisitors",
	},
	{
		title: "Account Creators",
		active: false,
		key: "accountCreators",
	},
	{
		title: "Ticket Purchases",
		active: false,
		key: "ticketPurchasers",
	},
];

export default function AudienceDemographics({
	globalDates,
}: {
	globalDates: Date[] | undefined;
}) {
	const { getAccessTokenSilently } = useAuth0();
	const { filmId } = useParams();
	const [data, setData] = useState<AudienceData>();
	const [demoOptions, setDemoOptions] = useState(defaultDemoOptions);

	useEffect(() => {
		const getAudienceData = async () => {
			const accessToken = await getAccessTokenSilently();
			let fetchedData;
			if (globalDates) {
				fetchedData = await fetchAudienceData(
					accessToken,
					filmId,
					globalDates[0],
					globalDates[1]
				);
			} else {
				fetchedData = await fetchAudienceData(accessToken, filmId);
			}
			setData(fetchedData);
		};
		getAudienceData();
	}, [globalDates]);

	const getActiveTab = (): keyof AudienceData => {
		for (let item of demoOptions) {
			if (item.active) {
				return item.key;
			}
		}
		// A default just to satisfy typescript
		return "siteVisitors";
	};

	return (
		<div className="flex flex-col items-start w-full p-8">
			<h3 className="mb-4">Audience Demographics</h3>
			<MultiTab labels={demoOptions} setLabels={setDemoOptions} />
			<div className="flex flex-row w-full gap-6">
				{data && data[getActiveTab()] && data[getActiveTab()].byAge && (
					<div className="w-1/2 h-[30vh] bg-shade2">
						<HorizBarGraph
							data={data[getActiveTab()].byAge}
							graphOptions={{}}
						/>
					</div>
				)}
				{data &&
					data[getActiveTab()] &&
					data[getActiveTab()].byGender && (
						<div className="w-1/2 h-[30vh] bg-shade2 flex flex-col m-8">
							<div className="w-full h-3/5">
								<PieGraph
									data={data[getActiveTab()].byGender}
									graphOptions={{}}
								/>
							</div>
							<Explicit data={data[getActiveTab()].byGender} />
						</div>
					)}
				{data &&
					data[getActiveTab()] &&
					data[getActiveTab()].byRegion && (
						<div className="w-1/2 h-[30vh] bg-shade2 m-8 flex flex-col justify-center items-center p-6">
							<HorizBarGraph
								data={data[getActiveTab()].byRegion.slice(0, 6)}
								graphOptions={{}}
							/>
						</div>
					)}
			</div>
		</div>
	);
}
