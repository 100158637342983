import { Outlet } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";


export default function AuthLayout() {
	  const userAgent = navigator.userAgent.toLowerCase();
	  const isMobileDevice = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent) 
							  || window.innerWidth <= 768;
  
	const navigate = useNavigate();
	// Allows person logging in to go directly to url originally specified
	const onRedirectCallback = (appState: any) => {
		navigate(appState.returnTo || window.location.pathname);
	};

	if (isMobileDevice) {
		return <MobileMessage />;
	  }

	return (
		<Auth0Provider
			domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
			clientId={process.env.REACT_APP_AUTH0_CLIENTID || ""}
			authorizationParams={{
				audience: process.env.REACT_APP_AUTH0_AUDIENCE,
				redirect_uri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
			}}
			onRedirectCallback={onRedirectCallback}
		>
			<Outlet />
		</Auth0Provider>
	);
}

const MobileMessage = () => {
  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h1>Mobile Version Not Supported</h1>
      <p>Please use a desktop or laptop to access this site.</p>
    </div>
  );
};
