import { BaseSyntheticEvent, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import Graph from "../../../Components/Graphs/Graph";
import FunnelGraph from "./FunnelSection";
import {
	fetchFilmOverallPerformance,
	fetchFilmOverallPerformanceRevenue,
} from "../../../Services/films/films";
import { Stat } from "../../../types/types";

import {
	graphOptions,
	LineGraphOptions,
	SingleCategoricalData,
} from "../../../types/types";
import { Histogram } from "../../../Components/Graphs/Histogram";
import logger from "../../../Utils/logging";
import { RevenueData } from "./types";
import { RevenueSection } from "./RevenueSection";
import StatsType1 from "../../../Components/Stats/StatsType1";

export default function OverallPerformance() {
	let { filmId } = useParams();
	const [revenueData, setRevenueData] = useState<RevenueData>();
	const [highlightData, setHighlightData] = useState<Stat[]>();
	const [funnelData, setFunnelData] = useState<SingleCategoricalData[]>();
	const [funnelHighlights, setFunnelHighlights] = useState<Stat[]>();
	const [funnelHover, setFunnelHover] = useState<SingleCategoricalData[]>();
	const { getAccessTokenSilently } = useAuth0();

	async function getFilmOverallPerformance(
		startDate: Date | undefined = undefined,
		endDate: Date | undefined = undefined
	) {
		try {
			const accessToken = await getAccessTokenSilently();
			const data = await fetchFilmOverallPerformance(
				accessToken,
				filmId,
				startDate,
				endDate
			);
			setRevenueData(data.revenueData);
			setHighlightData(data.highlights);
			setFunnelData(data.funnelData);
			setFunnelHighlights(data.funnelHighlights);
			setFunnelHover(data.funnelHover);
		} catch (err) {
			// showBoundary(err);
		}
	}

	useEffect(() => {
		getFilmOverallPerformance();
	}, [getAccessTokenSilently, filmId]);

	return (
		<div className="box-border flex flex-col w-full h-full">
			<div className="box-border w-full p-8">
				<h1 className="text-2xl">Highlights</h1>
				<StatsType1 stats={highlightData} />

				<h1 className="text-2xl">Revenue</h1>
				<RevenueSection
					revenueData={revenueData}
					getFilmOverallPerformance={getFilmOverallPerformance}
				/>
				<h1 className="text-2xl">User Funnel</h1>
				<div className="flex flex-row w-full h-[70vh] gap-8 p-6">
					<div className="flex flex-col w-[80vw]">
						{funnelData && funnelHover && (
							<FunnelGraph
								data={funnelData}
								hover={funnelHover}
							/>
						)}
					</div>
					<div className="">
						{funnelHighlights &&
							funnelHighlights.map((stat) => {
								const value = Number(stat.value);
								return (
									<div className="my-3 bg-shade2">
										<div className="pt-3 pl-3 text-2xl">
											{(value - Math.floor(value) != 0
												? value.toFixed(1)
												: value).toLocaleString() +
												(stat.suffix
													? stat.suffix
													: "")}
										</div>
										<div className="p-3 pt-1 text-sm">
											{stat.title}
										</div>
									</div>
								);
							})}
					</div>
					<div></div>
				</div>
			</div>
		</div>
	);
}
