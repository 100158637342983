import { Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export default function SendToHomePage() {
	const { user } = useAuth0();
	const role_property = process.env.REACT_APP_AUTH0_AUDIENCE + "/roles";
	const roles = user && user[role_property];
	// If user is undefined, it won't have gotten here
	return <Navigate to="/films" />;
	if (roles.includes("Admin")) {
		return <Navigate to="/admin/home" />;
	} else if (roles.includes("Internal")) {
		return <Navigate to="/internal/home" />;
	} else {
		return <Navigate to="/external/home" />;
	}
}
