import { ErrorBoundary, ErrorBoundaryContext } from "react-error-boundary";
import { Outlet } from "react-router-dom";
import ErrorPage from "../Pages/ErrorPage";
import SentryErrorPage from "../Pages/SentryErrorPage";
import logger from "../Utils/logging";
import * as Sentry from "@sentry/react";

// NOTE - MAKES NO DIFFERENCE TO FUNCTIONALITY IF YOU USE SENTRY ERROR BOUNDARY
// INSTEAD OF REACT-ERROR-BOUNDARY (both will send all info to sentry)
export default function ErrorBoundaryLayout() {
	return (
		<ErrorBoundary FallbackComponent={ErrorPage} onError={logError}>
			<Outlet />
		</ErrorBoundary>
		// <Sentry.ErrorBoundary
		// 	fallback={SentryErrorPage}
		// 	onError={sentryLogError}
		// >
		// 	<Outlet />
		// </Sentry.ErrorBoundary>
	);
}

function logError(error: Error, info: any) {
	console.log(error, info);
	logger.error(error, info);
	Sentry.captureException(error);
}

function sentryLogError(error: unknown) {
	console.log(error);
	logger.error(error);
}
