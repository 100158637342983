import {
	ResponsiveContainer,
	BarChart,
	Bar,
	XAxis,
	YAxis,
	Label,
	CartesianGrid,
	Tooltip,
} from "recharts";
import { GraphBundle } from "../../types/types";
import COLORS from "../../Utils/colors";

function CustomizedAxisTick(props: any) {
	const { x, y, stroke, payload } = props;
	return (
		<g transform={`translate(${x},${y})`}>
			<text
				x={0}
				y={10}
				dy={0}
				textAnchor="middle"
				fill="white"
				transform="rotate(-0)"
				style={{ fontSize: "0.65rem" }}
			>
				{payload.value}
			</text>
		</g>
	);
}

export default function BarGraph({ data, graphOptions }: GraphBundle) {
	let Xlabel;
	if (graphOptions.independent) {
		Xlabel = graphOptions.independent;
	} else {
		Xlabel = "";
	}
	let Ylabel;
	if (graphOptions.dependent) {
		Ylabel = graphOptions.dependent;
	} else {
		Ylabel = "";
	}

	return (
		<ResponsiveContainer height="100%" width="100%">
			<BarChart
				data={data}
				margin={{ bottom: 20 }}
				{...{
					overflow: "visible",
				}}
			>
				<XAxis
					dataKey="key"
					tick={CustomizedAxisTick}
					interval={0}
					label={{
						value: Xlabel,
						position: "bottom",
						offset: -5,
					}}
				/>
				<YAxis
					label={{
						value: Ylabel,
						position: "insideTopRight",
						fontSize: "0.875rem",
						offset: -20,
					}}
					tick={{ fontSize: "0.65rem", fill: "white" }}
					tickFormatter={(item) => {
						return item.toLocaleString();
					}}
				/>
				<Bar dataKey="value" fill={graphOptions.colour || COLORS[3]} />
				<Tooltip
					formatter={(item) => {
						return item.toLocaleString();
					}}
				/>
			</BarChart>
		</ResponsiveContainer>
	);
}
