import { Navigate, Outlet, useNavigate } from "react-router-dom";
import InnerNavigation from "./InnerNavigation";
import { useState, useEffect, BaseSyntheticEvent, useReducer } from "react";
import { join } from "path";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { PageLoader } from "../PageLoader";

export default function InnerNavigationLayout() {
	const Component = withAuthenticationRequired(
		() => {
			return (
				<div className="w-screen h-screen">
					<div className="w-[250px] h-full fixed">
						<InnerNavigation />
					</div>
					<div className="w-[calc(100vw-250px)] ml-[250px] h-full overflow-auto">
						<div className="h-[12vh] w-full">
							<h1 className="p-[3%]">Filmmaker Dashboard</h1>
						</div>
						<Outlet />
					</div>
				</div>
			);
		},
		{
			onRedirecting: () => {
				return (
					<div className="page-layout">
						<PageLoader />
					</div>
				);
			},
		}
		// {
		// 	loginOptions: {
		// 		fragment: "",
		// 	},
		// }
	);

	return <Component />;
}
