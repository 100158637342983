import { Stat } from "../../types/types";
export default function StatsType1({ stats }: { stats: Stat[] | undefined }) {
	return (
		<div className="flex my-3 flex-row min-h-[5vh] w-full max-w-[1080px] gap-6">
			{stats &&
				stats.map((stat) => {
					if (stat.prefix === undefined) {
						stat.prefix = "";
					}
					if (stat.suffix === undefined) {
						stat.suffix = "";
					}

					return (
						<div className="p-2 w-60 bg-shade" key={stat.title}>
							{stat.subtext && (
								<div className="text-xs italic text-shade1">
									{stat.subtext}
								</div>
							)}
							<div className="text-xl font-bold">
								{stat.value === "undefined" ||
								stat.value === undefined ||
								stat.value === "NaN" ||
								stat.value === null
									? "No data"
									: stat.prefix +
									  Number(stat.value).toLocaleString(
											undefined,
											{
												minimumFractionDigits:
													stat.decimalPlaces
														? stat.decimalPlaces
														: 0,
												maximumFractionDigits:
													stat.decimalPlaces
														? stat.decimalPlaces
														: 0,
											}
									  ) +
									  stat.suffix}
							</div>
							<div className="text-xs text-shade1">
								{stat.title}
							</div>
						</div>
					);
				})}
		</div>
	);
}
