import {
	ResponsiveContainer,
	LineChart,
	Line,
	XAxis,
	YAxis,
	Label,
	CartesianGrid,
	Tooltip,
} from "recharts";
import { TimeSeries, GraphOptionsBase, Series } from "../../../types/types";
import COLORS from "../../../Utils/colors";
import { stringToColor, weekdays, months } from "../../../Utils/utils";
import { RevenueTimeSeries } from "./types";

function CustomizedYAxisTick(props: any) {
	const { x, y, stroke, payload } = props;
	return (
		<g transform={`translate(${x},${y})`}>
			<text
				x={0}
				y={0}
				dy={0}
				fill="white"
				transform="rotate(-0)"
				textAnchor="end"
				style={{ fontSize: "0.75rem" }}
			>
				{"$" + Number(payload.value).toLocaleString()}
			</text>
		</g>
	);
}

const CustomTooltip = (props: any) => {
	const { active, payload, label } = props;
	if (active && payload && payload.length) {
		const date = new Date(payload[0].payload.date);
		const formattedDate = `${weekdays[date.getDay()]}, ${
			months[date.getMonth()]
		} ${date.getDate()}`;
		return (
			<div className="p-3 text-xs text-dark bg-light ">
				<p className="pb-2 font-bold">{`${formattedDate}`}</p>
				{payload.map((item: any) => {
					return (
						<p>
							<span
								style={{
									color: stringToColor(item.dataKey, COLORS),
								}}
							>
								--
							</span>
							{` ${item.dataKey.replace("_", " ")}:     ${
								"$" + Number(item.value).toFixed(2)
							}`}
						</p>
					);
				})}
			</div>
		);
	}

	return null;
};

export default function RevenueGraph({
	data,
	graphOptions,
	dataKey,
}: {
	data: RevenueTimeSeries[] | undefined;
	graphOptions: GraphOptionsBase;
	dataKey: string;
}) {
	return (
		data && (
			<ResponsiveContainer height="100%" width="100%">
				<LineChart
					data={data}
					{...{
						overflow: "visible",
					}}
				>
					<XAxis
						dataKey="date"
						// scale="utc"
						// type="number"
						style={{
							fontSize: "0.75rem",
							fill: "white",
						}}
						label={{
							value: graphOptions.independent,
							position: "insideBottomRight",
							offset: -5,
							fontSize: "0.75rem",
						}}
					/>
					<YAxis
						type="number"
						height={100}
						width={30}
						tick={CustomizedYAxisTick}
						label={{
							value: graphOptions.dependent,
							position: "insideTopLeft",
							fontSize: "0.75rem",
							offset: -25,
						}}
					/>
					<Line
						dataKey={dataKey}
						fill="#8884d8"
						isAnimationActive={false}
						style={{
							stroke: stringToColor(dataKey, COLORS),
						}}
						dot={data.length < 15}
					/>
					<Tooltip content={<CustomTooltip />} />
				</LineChart>
			</ResponsiveContainer>
		)
	);
}
