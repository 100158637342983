import "./sentry";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import * as Sentry from "@sentry/react";

// Sentry.init({
// 	dsn: "https://f98261b0d01c755400ec7f4f10e8ab84@o4507696758784000.ingest.de.sentry.io/4507696761798736",
// 	integrations: [
// 		Sentry.browserTracingIntegration(),
// 		Sentry.browserProfilingIntegration(),
// 		Sentry.replayIntegration(),
// 	],
// 	// Performance Monitoring
// 	tracesSampleRate: 1.0, //  Capture 100% of the transactions
// 	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
// 	tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
// 	// Profiling
// 	profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
// 	// Session Replay
// 	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
// 	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
