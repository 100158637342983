import { useState } from "react";
import ByChannel from "./ByChannel";
import AudienceDemographics from "./AudienceDemographics";

export default function ReachTactics() {
	const [globalDates, setGlobalDates] = useState<Date[]>();

	return (
		<div className="box-border flex flex-col items-center w-full">
			<ByChannel setGlobalDates={setGlobalDates} />
			<AudienceDemographics globalDates={globalDates} />
			<div className="h-[30vh] w-full"></div>
		</div>
	);
}
