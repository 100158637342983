import { timeStamp } from "console";
import pino from "pino";

const logger = pino({
	browser: {
		transmit: {
			level: "info",
			send: function (level, logEvent) {
				const domain = process.env.REACT_APP_API_URL || "";
				fetch(domain + "/frontendLogging", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(logEvent),
				});
			},
		},
	},
});

export default logger;
