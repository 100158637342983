export default function ErrorPage({
	error,
	resetErrorBoundary,
}: {
	error: Error;
	resetErrorBoundary: () => void;
}) {
	return (
		<div className="w-full h-[100vh]">
			<div className="flex flex-col items-center">
				<h1 className="mt-8 mb-12">Oops!</h1>
				<div className="flex flex-col">
					<h3>Sorry, an unexpected error has occured: </h3>
					<p>
						<div className="p-3 my-3 bg-slate-900 rounded-xl">
							{process.env.NODE_ENV === "development" ? (
								<i>{error.stack || error.message}</i>
							) : (
								<i>{error.message}</i>
							)}
						</div>
					</p>
					<button
						type="button"
						onClick={resetErrorBoundary}
						className="self-center px-3 py-2 mt-3 border border-solid rounded-lg border-accent1"
					>
						Reset here
					</button>
				</div>
			</div>
		</div>
	);
}
