import { MouseEventHandler } from "react";

interface DatePickerProps {
	startDate: string;
	endDate: string;
	setStartDate: (date: string) => void;
	setEndDate: (date: string) => void;
	onApply: MouseEventHandler;
}

const DatePicker: React.FC<DatePickerProps> = ({
	startDate,
	endDate,
	setStartDate,
	setEndDate,
	onApply,
}) => {
	// Handle change for the start date
	const handleStartDateChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setStartDate(event.target.value);
	};

	// Handle change for the end date
	const handleEndDateChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setEndDate(event.target.value);
	};

	return (
		<div className="flex flex-row gap-4 min-w-[200px] items-end text-xs mb-4">
			<div>Date Range</div>
			<div className="flex flex-row gap-2">
				<label className="flex flex-col">
					<div>Start</div>
					<input
						type="date"
						value={startDate}
						onChange={handleStartDateChange}
						className="p-0.5 text-black rounded-md"
					/>
				</label>
				<div className="self-end">-</div>
				<label className="flex flex-col">
					<div>End</div>
					<input
						type="date"
						value={endDate}
						onChange={handleEndDateChange}
						className="p-0.5 text-black rounded-md"
					/>
				</label>
			</div>
			<button
				type="button"
				className="px-3 py-1 rounded-md bg-accent1 "
				onClick={onApply}
			>
				Apply
			</button>
		</div>
	);
};

export default DatePicker;
