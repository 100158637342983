import { useState, useEffect } from "react";
import { FilmForFilms } from "./types";
import { Purify } from "../../Components/Utils/HTMLCleaners";
import { Link } from "react-router-dom";
import Graph from "../../Components/Graphs/Graph";
import { Histogram } from "../../Components/Graphs/Histogram";

export default function FilmContainer({ film }: { film: FilmForFilms }) {
	const [lines, setLines] = useState<string>("line-clamp-[7]");

	const handleResize = () => {
		const vh = window.innerHeight;
		const baseLineHeight = 14 * 1.25;
		let lines = (vh * 0.3 * 0.6) / baseLineHeight - 2;
		const final = Math.round(lines);
		setLines(`line-clamp-[${final}]`);
	};
	// This seems weird, but just by putting this in code tailwind will know to
	// include the classes we want - it has to see raw unbroken strings of
	// classes
	const lol = [
		"line-clamp-[1]",
		"line-clamp-[2]",
		"line-clamp-[3]",
		"line-clamp-[4]",
		"line-clamp-[5]",
		"line-clamp-[6]",
		"line-clamp-[7]",
		"line-clamp-[8]",
		"line-clamp-[9]",
		"line-clamp-[10]",
		"line-clamp-[11]",
		"line-clamp-[12]",
	];

	window.addEventListener("resize", handleResize);

	useEffect(() => {
		handleResize();
	}, []);

	return (
		<Link to={`${film.overview.id}`} title={`${film.overview.title}`}>
			<div className="flex flex-row items-center justify-between w-full bg-shade2 rounded-3xl h-[30vh] pr-5">
				<div className="relative w-1/5 p-[2%] text-xl align-middle h-full">
					<img
						className="object-contain w-full h-full rounded-xl"
						src={`${process.env.REACT_APP_CLOUDFRONT_DOMAIN}${film.overview.img_path}`}
						alt="logo"
					/>
				</div>
				<div className="flex flex-col justify-start flex-1 w-1/2 min-w-[200px] h-full text-ellipsis pt-4">
					<h3>{film.overview.title}</h3>
					<div className="flex flex-row flex-1 text-sm">
						<div className="flex flex-col w-full pb-3">
							<div className="pb-3">
								{film.overview.duration +
									" | " +
									film.overview.rating}
								<br />
								Genres: {film.overview.genres.join(", ")}
								{/* <div>
									{"Release Date: " +
										getDate(film.overview.Rel_date)}
								</div> */}
								{/* <div>
									{"Director: " + film.overview.Director}
								</div> */}
							</div>
							<div className={lines}>
								<Purify
									htmlString={film.overview.description}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="flex flex-row items-center justify-center flex-1 pt-5 h-5/6">
					<div className="w-5/6 h-full">
						{film.revenue && film.revenue.timeSeries && (
							<Graph
								data={film.revenue.timeSeries}
								graphOptions={film.revenue.graphOptions}
							/>
						)}
					</div>
				</div>
			</div>
		</Link>
	);
}
