import {
	Series,
	TimeSeries,
	SingleCategoricalData,
	LineGraphOptions,
	graphOptionsBase,
} from "./types";

// It's not a perfect check, but it's just to keep typescript happy.
export function IsSeries(
	data: TimeSeries[] | Series[] | SingleCategoricalData[]
): data is Series[] {
	let x = data[0];
	return typeof x === "object" && !Array.isArray(x) && x !== null;
}

export function isLineGraphOptions(
	graphOptions: graphOptionsBase
): graphOptions is LineGraphOptions {
	return graphOptions.graphType === "Line";
}
