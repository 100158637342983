import {
	ResponsiveContainer,
	BarChart,
	Bar,
	XAxis,
	YAxis,
	Label,
	CartesianGrid,
	Tooltip,
} from "recharts";
import { GraphBundle } from "../../types/types";
import COLORS from "../../Utils/colors";

function getFormattedLabel(s: string) {
	const MAX_WIDTH = 14;
	const THRESHOLD = MAX_WIDTH - 2;
	const MAX_LINES = 2;

	if (s.length > MAX_WIDTH) {
		const lines: string[] = [];
		const words = s.split(" ");
		let nLines = 0;
		let current_line = "";
		let complete = false;
		while (nLines < MAX_LINES) {
			let next_word; // Should have been fine with conditional below, but f you typescript
			if (words.length > 0) {
				next_word = words.splice(0, 1)[0];
			} else {
				complete = true;
				break;
			}
			if (next_word.length + current_line.length < THRESHOLD) {
				if (current_line.length === 0) {
					current_line = next_word;
				} else {
					current_line += " " + next_word; // Seems to violate 12-character limit, but deliberately we allow 14 characters max to simplify things
				}
			} else if (current_line.length === 0) {
				if (next_word.length < MAX_WIDTH + 1) {
					current_line = next_word;
					lines.push(current_line);
					nLines += 1;
					current_line = "";
				} else {
					current_line = next_word.slice(0, THRESHOLD) + "-";
					lines.push(current_line);
					words.splice(0, 0, next_word.slice(THRESHOLD));
					nLines += 1;
					current_line = "";
				}
			} else {
				if (current_line.length <= 3) {
					let allowableSlice = "";
					if (
						current_line.length + next_word.length - THRESHOLD <
						3
					) {
						allowableSlice = next_word.slice(
							0,
							THRESHOLD - current_line.length - 2
						);
					} else {
						allowableSlice = next_word.slice(
							0,
							THRESHOLD - current_line.length
						);
					}

					current_line += " " + allowableSlice + "-";
					words.splice(0, 0, next_word.slice(allowableSlice.length));
				} else {
					words.splice(0, 0, next_word);
				}
				lines.push(current_line);
				current_line = "";
				nLines += 1;
			}
			if (words.length === 0) {
				complete = true;
				lines.push(current_line);
				break;
			}
		}
		if (!complete) {
			const lastLineCurrentLength = lines[lines.length - 1].length;
			let wordToAdd = "";
			if (THRESHOLD - lastLineCurrentLength > 0) {
				if (THRESHOLD - lastLineCurrentLength - 2 > 0) {
					wordToAdd =
						" " +
						words
							.splice(0, 1)[0]
							.slice(0, THRESHOLD - lastLineCurrentLength - 2);
				}
			}
			lines[lines.length - 1] =
				lines[lines.length - 1] + wordToAdd + "...";
		}

		let outputLines: any[] = [];
		let first_time = true;
		for (let line of lines) {
			if (first_time) {
				outputLines.push(<tspan x="0">{line}</tspan>);
				first_time = false;
				continue;
			}
			outputLines.push(
				<tspan x="0" dy="1.2em">
					{line}
				</tspan>
			);
		}

		return outputLines;
	} else {
		return s;
	}
}

// Some test cases for the above functions (put as one of the keys in the database):
// "Heterosexualiliratearily inclinerated
// "Heterosexually inclinated sirree"
// "He inclinerated sirree"
// "He inclinerated sirree buckaroo Ramona"
// Try these with multiple numbers of lines (especially 2 & 3)

function CustomizedAxisTick(props: any) {
	const { x, y, stroke, payload } = props;
	return (
		<g transform={`translate(${x},${y})`}>
			<text
				// x={0}
				// y={0}
				// dy={0}
				fill="white"
				textAnchor="end"
				transform="rotate(-0)"
				style={{ fontSize: "0.65rem" }}
			>
				{getFormattedLabel(payload.value)}
			</text>
		</g>
	);
}

export default function HorizBarGraph({ data, graphOptions }: GraphBundle) {
	return (
		<ResponsiveContainer height="100%" width="100%">
			<BarChart
				data={data}
				layout="vertical"
				margin={{ left: 20, bottom: 20 }}
				{...{
					overflow: "visible",
				}}
			>
				<XAxis
					dataKey="value"
					type="number"
					style={{ fontSize: "0.65rem", fill: "white" }}
					label={{
						value: graphOptions.dependent || "",
						position: "bottom",
						offset: -5,
					}}
					tickFormatter={(item) => {
						return item.toLocaleString();
					}}
				/>
				<YAxis
					dataKey="key"
					type="category"
					style={{ fontSize: "0.65rem" }}
					interval={0}
					tick={CustomizedAxisTick}
					label={{
						value: graphOptions.independent || "",
						position: "insideTopRight",
						fontSize: "0.875rem",
						offset: -20,
					}}
				/>
				<Bar
					dataKey="value"
					fill={graphOptions.colour || COLORS[0]}
					isAnimationActive={true}
				/>
				<Tooltip content={<CustomTooltip />}/>
			</BarChart>
		</ResponsiveContainer>
	);
}

function CustomTooltip(props: any) {
	const { active, payload } = props;
	if (active && payload && payload.length) {
		const actual_payload = payload[0].payload;
		return (
			<div className="text-dark bg-light p-3">
				<p>{`${actual_payload.key}`}</p>
				<p>{`${actual_payload.value.toLocaleString()}`}</p>
			</div>
		);
	}
}
