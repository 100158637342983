import { UserBasic } from "./types";
import { FormEventHandler } from "react";

export default function UserSection({
	userList,
	handleUserSelect,
}: {
	userList: UserBasic[];
	handleUserSelect: FormEventHandler<HTMLInputElement>;
}) {
	return (
		<div className="p-3 border border-gray-500 border-solid rounded-lg">
			<fieldset className=" h-[30vh] overflow-y-scroll scrollbar relative ">
				<legend className="">
					<h2 className="m-2">Select User</h2>
				</legend>
				<div className="pr-3">
					<div className="sticky top-0 flex flex-row justify-between mb-3 ml-2 border-b-2 border-solid bg-dark border-accent1">
						<div className="flex flex-row">
							<div className="w-[250px]">Name</div>
							<div>Email</div>
						</div>
					</div>
					{userList.map((user) => {
						return (
							<div
								className="flex flex-row m-2 border-b-[1px] border-shade1 border-solid justify-between"
								key={user.user_id}
							>
								<label
									className="flex flex-row"
									htmlFor={user.user_id}
								>
									<div className="w-[250px]">{user.name}</div>
									<div>{user.email}</div>
								</label>
								<input
									type="radio"
									value={user.user_id}
									name={"user_id"}
									onInput={handleUserSelect}
								/>
							</div>
						);
					})}
				</div>
			</fieldset>
		</div>
	);
}
