const COLORS = [
	"#0088FE",
	"#00C49F",
	"#FFBB28",
	"#FF8042",
	"red",
	"pink",
	"#17A589",
	"#F39C12",
	"#7D3C98",
	"#2E4053",
	"#2e4053",
	"#f39c12",
	"#17a589",
	"#2874a6",
	"#DAF7A6",
	"#40E0D0",
	"#DE3163",
	"#CCCCFF",
	"#6495ED",
	"#33FF89",
];

export default COLORS;
