import { useState, useEffect } from "react";
import { GraphBundle } from "../../types/types";
import BarGraph from "./BarGraph";
import LineGraph from "./LineGraph";
import PieGraph from "./PieGraph";
import HorizBarGraph from "./HorizBarGraph";
import { isLineGraphOptions, IsSeries } from "../../types/typeguards";

export default function Graph({ data, graphOptions }: GraphBundle) {
	const [graphType, setGraphType] = useState(graphOptions["graphType"]);
	let base;
	switch (graphType) {
		case "Bar":
			base = <BarGraph data={data} graphOptions={graphOptions} />;
			break;
		case "Line":
			if (IsSeries(data) && isLineGraphOptions(graphOptions)) {
				base = <LineGraph data={data} graphOptions={graphOptions} />;
			}
			break;
		case "Pie":
			base = <PieGraph data={data} graphOptions={graphOptions} />;
			break;
		case "HorizBar":
			base = <HorizBarGraph data={data} graphOptions={graphOptions} />;
			break;
		default:
			base = <HorizBarGraph data={data} graphOptions={graphOptions} />;
	}

	let w = "w-full";
	let h = "h-full";
	if (graphOptions.width) {
		// w = "w-[" + graphOptions.width + "px]";
		w = graphOptions.width;
	}
	if (graphOptions.height) {
		h = graphOptions.height;
	}

	let size = w + " " + h;
	let header_class =
		graphType === "Pie" ? "pb-2 text-center" : "pb-2 ml-12 text-center";
	return (
		<div className={size}>
			{graphOptions.title && (
				<h2 className={header_class + " text-sm"}>
					{graphOptions.title}
				</h2>
			)}
			{base}
		</div>
	);
}
