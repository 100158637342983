import { ReactNode, SyntheticEvent } from "react";

export default function SelectContainer({
	items,
	setItems,
	LabelElement,
	type,
}: // onHover,
{
	items: { id: string; checked: boolean; disabled?: boolean }[] | undefined;
	setItems: Function;
	LabelElement: React.FC<{
		children: ReactNode;
		item: { id: string; checked: boolean; disabled?: boolean };
	}>;
	type: "radio" | "checkbox";
	// onHover?: React.MouseEventHandler<HTMLInputElement>;
}) {
	function handleSelect(event: SyntheticEvent) {
		const target = event.target as HTMLInputElement;
		const id = target.value;
		const checked = target.checked;
		const itemsList = JSON.parse(JSON.stringify(items));
		// Conditional for typescript's sake, will never be true
		if (!items) {
			return;
		}
		for (let i = 0; i < items?.length; i++) {
			if (items[i].id === id) {
				itemsList[i].checked = !checked;
			} else if (type === "radio") {
				itemsList[i].checked = false;
			}
		}
		setItems(itemsList);
	}
	return (
		<>
			{items &&
				items.map((item) => {
					return (
						<div
							className={
								"flex flex-row justify-between border-b-[1px] border-shade1 border-solid w-full "
							}
						>
							<LabelElement item={item}>
								<input
									type={type}
									value={item.id}
									name={item.id}
									onMouseDown={handleSelect}
									checked={item.checked}
									className="mx-4"
									onChange={() => {}}
									key={item.id}
									disabled={item.disabled}
									// onMouseEnter={
									// 	onHover
									// 		? onHover
									// 		: (event: SyntheticEvent) => {}
									// }
								/>
							</LabelElement>
						</div>
					);
				})}
		</>
	);
}
