import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { fetchFilmPerformanceHighlights } from "../../../Services/films/films";

import { RevenueData, Highlight } from "./types";
import FilmEventRevenueSection from "./FilmEventRevenueSection";

export default function FilmPerformance() {
	const [highlights, setHighlights] = useState<Highlight[]>();
	const { getAccessTokenSilently } = useAuth0();
	const { filmId } = useParams();

	useEffect(() => {
		async function getHighlights() {
			const accessToken = await getAccessTokenSilently();
			const res = await fetchFilmPerformanceHighlights(
				accessToken,
				filmId
			);
			if (res) {
				setHighlights(res);
			}
		}
		getHighlights();
	}, [filmId]);

	return (
		<>
			<div className="w-[100%] box-border flex flex-col items-left">
				{/* <StatsType1
					stats={[
						{
							name: "Total Revenue",
							value:
								highlights &&
								String(
									Number(highlights.totalRevenue).toFixed(2)
								),
							prefix: "$ ",
						},
						{
							name: "Total Costs",
							value:
								highlights &&
								String(
									Number(highlights.totalCosts).toFixed(2)
								),
							prefix: "$ ",
						},
					]}
				/> */}
			</div>

			<FilmEventRevenueSection />

			<div className="h-[50vh] w-full"></div>
		</>
	);
}
