import { useState, useEffect, BaseSyntheticEvent } from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import SearchBar from "./SearchBar";
import { fetchUserFilmsData } from "../../Services/films/films";

import { FilmForFilms } from "./types";
// import { FilmForFilms } from "../../../types/dist/src/films";
import FilmContainer from "./FilmContainer";

import { useErrorBoundary } from "react-error-boundary";

export default function FilmsPage() {
	const [Films, setFilms] = useState<FilmForFilms[]>();
	const { getAccessTokenSilently } = useAuth0();
	// const { showBoundary } = useErrorBoundary();

	useEffect(() => {
		async function getFilms() {
			const accessToken = await getAccessTokenSilently();
			const fetchedFilms = await fetchUserFilmsData(accessToken);
			setFilms(fetchedFilms);
		}

		getFilms();
		// No need for a cleanup function yet - only fetching once
	}, []);

	// const whitelist = [
	// 	"Of Medicine and Miracles",
	// 	"While We Watched",
	// 	"THE SPOILS",
	// 	"Your Fat Friend",
	// 	"Copa 71",
	// ];

	return (
		<div className="flex flex-col items-center w-full gap-10">
			{/* <button
				type="button"
				onClick={() => {
					// showBoundary("Sentry Test Error");
					throw new Error("Sentry Test Error");
				}}
			>
				Break the world
			</button> */}
			{/* <SearchBar /> */}
			<div className="flex flex-col w-4/5 gap-5 mt-5">
				{Films &&
					Films.length === 0 &&
					"You are not registered as having access to any films." +
						" Please try refreshing the page." +
						" If this does not work you do not yet have permission to view any films." +
						"Please contact an administrator, they will grant any appropriate permissions."}
				{Films &&
					Films.map((film) => {
						// if (whitelist.includes(film.overview.title)) {
						return (
							<FilmContainer
								film={film}
								key={film.overview.title}
							/>
						);
						// }
					})}
			</div>
		</div>
	);
}
