import { RevenueData } from "../../Pages/Film/OverallPerformance/types";
import { authGet, authPost } from "../../Utils/fetches";

export async function fetchUserFilmsData(accessToken: string) {
	const path = `/filmsData/films`;
	return await authGet(accessToken, path);
}

export async function fetchFilmName(
	accessToken: string,
	filmId: string | undefined
) {
	const path = `/filmsData/filmname/${filmId}`;
	return await authGet(accessToken, path);
}

export async function fetchFilmOverview(
	accessToken: string,
	filmId: string | undefined
) {
	const path = `/filmsData/${filmId}/film`;
	return await authGet(accessToken, path);
}

export async function fetchFilmOverallPerformance(
	accessToken: string,
	filmId: string | undefined,
	startDate: Date | undefined = undefined,
	endDate: Date | undefined = undefined
) {
	let path = `/filmsData/${filmId}/overallPerformance`;
	if (startDate && endDate) {
		const queryParams = new URLSearchParams({
			startDate: startDate.toISOString(),
			endDate: endDate.toISOString(),
		}).toString();
		path += "?" + queryParams;
	}
	return await authGet(accessToken, path);
}

export async function fetchFilmOverallPerformanceRevenue(
	accessToken: string,
	filmId: string | undefined,
	startDate: Date | undefined = undefined,
	endDate: Date | undefined = undefined
) {
	try {
		let path = `/filmsData/${filmId}/overallPerformanceRevenue`;
		if (startDate && endDate) {
			const queryParams = new URLSearchParams({
				startDate: startDate.toISOString(),
				endDate: endDate.toISOString(),
			}).toString();
			path += "?" + queryParams;
		}
		const revenueData: RevenueData | undefined = await authGet(
			accessToken,
			path
		);
		return revenueData;
	} catch (err) {
		console.log(err);
	}
}

export async function fetchMarketingSpend(
	accessToken: string,
	filmId: string | undefined
) {
	let path = `/filmsData/${filmId}/marketingSpend`;
	const data = await authGet(accessToken, path);
	return data;
}

export async function fetchReachTactics(
	accessToken: string,
	filmId: string | undefined,
	startDate: Date | undefined,
	endDate: Date | undefined
) {
	let path = `/filmsData/${filmId}/reachPerformance`;
	if (startDate && endDate) {
		const queryParams = new URLSearchParams({
			startDate: startDate.toISOString(),
			endDate: endDate.toISOString(),
		}).toString();
		path += "?" + queryParams;
	}
	const data = await authGet(accessToken, path);
	return data;
}

export async function fetchAudienceData(
	accessToken: string,
	filmId: string | undefined,
	startDate: Date | undefined = undefined,
	endDate: Date | undefined = undefined
) {
	let path = `/filmsData/${filmId}/reachDemographics`;
	if (startDate && endDate) {
		const queryParams = new URLSearchParams({
			startDate: startDate.toISOString(),
			endDate: endDate.toISOString(),
		}).toString();
		path += "?" + queryParams;
	}
	const data = await authGet(accessToken, path);
	return data;
}

export async function fetchWatchPerformance(
	accessToken: string,
	filmId: string | undefined
) {
	const path = `/filmsData/${filmId}/watchPerformance`;
	const data = await authGet(accessToken, path);
	return data;
}

export async function fetchFilmPerformanceRevenue(
	accessToken: string,
	filmId: string | undefined,
	startDate: Date | undefined = undefined,
	endDate: Date | undefined = undefined
) {
	try {
		let path = `/filmsData/${filmId}/filmPerformanceRevenue`;
		if (startDate && endDate) {
			const queryParams = new URLSearchParams({
				startDate: startDate.toISOString(),
				endDate: endDate.toISOString(),
			}).toString();
			path += "?" + queryParams;
		}
		const revenueData = await authGet(accessToken, path);
		return revenueData;
	} catch (err) {
		console.log(err);
	}
}

export async function fetchFilmPerformanceHighlights(
	accessToken: string,
	filmId: string | undefined
) {
	let path;
	if (filmId) {
		path = `/filmsData/${filmId}/revenue/highlights`;
	} else {
		path = `/allFilmsData/revenue/highlights`;
	}

	const highlights = await authGet(accessToken, path);
	return highlights;
}

export async function fetchFilmRevenueOverview(
	accessToken: string,
	filmId: string | undefined
) {
	const path = `/filmsData/` + filmId + "/revenueOverview";
	const hi = await authGet(accessToken, path);
	console.log(hi);
	return hi;
}

export async function fetchFilmAudienceOverview(
	accessToken: string,
	filmId: string | undefined
) {
	const path = `/filmsData/` + filmId + "/audienceOverview";
	return await authGet(accessToken, path);
}

export async function fetchFilmCampaignOverview(
	accessToken: string,
	filmId: string | undefined
) {
	const path = `/filmsData/` + filmId + "/campaignOverview";
	return await authGet(accessToken, path);
}
