import {
	ResponsiveContainer,
	BarChart,
	LineChart,
	Line,
	Bar,
	XAxis,
	YAxis,
	Label,
	CartesianGrid,
	Tooltip,
	PieChart,
	Pie,
	Sector,
	Cell,
} from "recharts";
import { CategoricalGraphBundle, graphOptions } from "../../../types/types";
import COLORS from "../../../Utils/colors";
import {
	stringToColor,
	uniqueStringArrayToColours,
} from "../../../Utils/utils";

interface labelCustomiser {
	[key: string]: number;
}

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = (props: labelCustomiser) => {
	const {
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		percent,
		index,
		key,
		fill,
	} = props;
	const radius = outerRadius + (outerRadius - innerRadius) * 1;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);
	const lx = cx + outerRadius * Math.cos(-midAngle * RADIAN);
	const ly = cy + outerRadius * Math.sin(-midAngle * RADIAN);
	const lex = lx + (x - lx) * 0.7;
	const ley = ly + (y - ly) * 0.7;
	return (
		<g>
			<path
				d={`M${lx},${ly}L${lex},${ley}`}
				stroke={String(fill)}
				fill={String(fill)}
			/>
			<text
				style={{ fontSize: "0.8rem" }}
				fill={String(fill)} // if you want black text, just change this to "black"
				x={x}
				y={y}
				textAnchor={x > cx ? "start" : "end"}
				dominantBaseline="central"
				//  lengthAdjust="spacingAndGlyphs"
				//  textLength="15%"
			>
				{`${key}`}
			</text>
		</g>
	);
};

const CustomTooltip = (props: any) => {
	const { active, payload, label } = props;
	if (active && payload && payload.length) {
		const date = payload[0].payload.key;
		return (
			<div className="p-3 text-dark bg-light">
				<p className="text-xl">{`${
					"$" + Number(payload[0].payload.value).toLocaleString()
				}`}</p>
				<p className="text-sm">{`${date}`}</p>
			</div>
		);
	}

	return null;
};

export default function PieGraph({
	data,
	graphOptions,
}: CategoricalGraphBundle) {
	const colorMap = uniqueStringArrayToColours(
		data.map((item: { key: string; value: number }) => {
			return item.key;
		}),
		COLORS
	);
	return (
		<ResponsiveContainer width="100%" height="100%">
			<PieChart
				{...{
					overflow: "visible",
				}}
			>
				<Pie
					data={data}
					cx="50%"
					cy="50%"
					labelLine={false}
					// label={renderCustomizedLabel}
					innerRadius="50%"
					outerRadius="70%"
					fill="#8884d8"
					dataKey="value"
					nameKey="key"
					isAnimationActive={true}
				>
					{data.map(({ key, value }, index) => {
						return (
							<Cell
								key={`cell-${key}`}
								fill={colorMap.get(key)}
							/>
						);
					})}
				</Pie>
				<Tooltip content={<CustomTooltip />} />
			</PieChart>
		</ResponsiveContainer>
	);
}
