import logger from "./logging";

export async function authPost(accessToken: string, path: string, body: any) {
	const domain = process.env.REACT_APP_API_URL || "";
	const uri = domain + path;
	try {
		const res = await fetch(uri, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify(body),
		});
		const name = await res.json();
		return name;
	} catch (err) {
		console.error(
			`POST request to uri ${uri} with body:\n${JSON.stringify(
				body,
				undefined,
				4
			)} failed`
		);
		logger.warn(err);
		return;
	}
}

export async function authGet(accessToken: string, path: string) {
	const domain = process.env.REACT_APP_API_URL || "";
	const uri = domain + path;
	try {
		const res = await fetch(domain + path, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		});
		const name = await res.json();
		return name;
	} catch (err) {
		logger.warn(err);
		return;
	}
}
