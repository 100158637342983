import Chatbot from "./ChatbotInterface";
import logger from "../../../Utils/logging";

export default function ChatbotPage() {
	return (
		<div className="flex flex-col items-center">
			<div className="px-[5%] w-[90%] box-border flex flex-col items-left">
				<h1 className=" mt-[5%] text-4xl font-serif">Ask JoltER</h1>
				<div className="h-[50vh] w-full">
					<Chatbot />
				</div>
			</div>
		</div>
	);
}
