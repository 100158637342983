import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useState, useEffect, BaseSyntheticEvent, useReducer } from "react";
import { join } from "path";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { PageLoader } from "../Components/PageLoader";

export default function AuthenticationGuard() {
	const Component = withAuthenticationRequired(
		() => {
			return <Outlet />;
		},
		{
			onRedirecting: () => {
				return (
					<div className="page-layout">
						<PageLoader />
					</div>
				);
			},
		}
		// {
		// 	loginOptions: {
		// 		fragment: "",
		// 	},
		// }
	);

	return <Component />;
}
