import { useState, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { WatchPerformanceData } from "./types";

import StatsType1 from "../../../Components/Stats/StatsType1";

import { fetchWatchPerformance } from "../../../Services/films/films";
import HorizBarGraph from "../../../Components/Graphs/HorizBarGraph";
import FunnelGraph from "./FunnelGraph";

export default function WatchPerformance() {
	const { filmId } = useParams();
	const { getAccessTokenSilently } = useAuth0();
	const [data, setData] = useState<WatchPerformanceData>();

	useEffect(() => {
		async function getWatchPerformance() {
			const accessToken = await getAccessTokenSilently();
			const res: WatchPerformanceData = await fetchWatchPerformance(
				accessToken,
				filmId
			);
			setData(res);
		}
		getWatchPerformance();
	}, []);

	return (
		<>
		<div>
            <h3 className="mb-4" style={{ marginLeft: '50px' }}>Highlights</h3>
        </div>
			<div className="w-[100%] box-border flex flex-col px-12">
				{data && <StatsType1 stats={data.stats} />}
			</div>
		<div>
            <h3 className="mb-0" style={{ marginLeft: '50px' }}>Watch Performance</h3>
        </div>

			<div className="w-[100%] box-border flex flex-col items-center">
				<div className="flex flex-row w-full h-[70vh] gap-8 p-12">
					<div className="flex flex-col w-1/2 h-full bg-shade2">
						<h2 className="p-6">Film Watches By Country</h2>
						<div className="flex-grow w-full p-3">
							{data && data.ByCountry && (
								<HorizBarGraph
									data={data.ByCountry.slice(0, 8)}
									graphOptions={{}}
								/>
							)}
						</div>
					</div>
					<div className="flex flex-col flex-grow bg-shade2">
						<h2 className="p-6">Funnel Analysis</h2>
						<div className="flex-grow w-full h-full bg-shade2">
							{data && data.funnel && (
								<FunnelGraph data={data.funnel} />
							)}
						</div>
					</div>
				</div>
				<div className="h-[50vh] w-full"></div>
			</div>
		</>
	);
}
