import { SingleCategoricalData } from "../../../types/types";
import { uniqueStringArrayToColours } from "../../../Utils/utils";
import COLORS from "../../../Utils/colors";

export default function Explicit({ data }: { data: SingleCategoricalData[] }) {
	const colorMap = uniqueStringArrayToColours(
		data.map((item: { key: string; value: number }) => {
			return item.key;
		}),
		COLORS
	);
	const total = data.reduce((acc, curr) => {
		return acc + curr.value;
	}, 0);
	const dataWithPercent = data.map((item: SingleCategoricalData) => {
		return { ...item, percent: Math.round((item.value / total) * 100) };
	});

	return (
		<div className="h-[30%] w-full flex flex-row justify-center">
			{dataWithPercent.map((item: SingleCategoricalData) => {
				return (
					<div className="flex flex-col items-center p-3">
						<div className="flex flex-row items-center flex-grow">
							<SvgCircle
								color={colorMap.get(item.key)}
								size={10}
							/>
							<div>{item.key}</div>
						</div>
						<div className="text-xl">{item.percent}%</div>
					</div>
				);
			})}
		</div>
	);
}

const SvgCircle: React.FC<{ color: string; size?: number }> = ({
	color,
	size = 10,
}) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 100 100"
			xmlns="http://www.w3.org/2000/svg"
			style={{ marginRight: "8px", verticalAlign: "middle" }} // Optional styling
		>
			<circle cx="50" cy="50" r="50" fill={color} />
		</svg>
	);
};
