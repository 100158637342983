import {
	ResponsiveContainer,
	LineChart,
	Line,
	XAxis,
	YAxis,
	Label,
	CartesianGrid,
	Tooltip,
} from "recharts";
import { TimeSeries, GraphOptionsBase } from "../../../types/types";
import { MultiPlatformTimeSeries } from "./types";
import COLORS from "../../../Utils/colors";
import { stringToColor, weekdays, months } from "../../../Utils/utils";

function CustomizedYAxisTick(props: any) {
	const { x, y, stroke, payload } = props;
	return (
		<g transform={`translate(${x},${y})`}>
			<text
				x={0}
				y={0}
				dy={0}
				fill="white"
				transform="rotate(-0)"
				textAnchor="end"
				style={{ fontSize: "0.75rem" }}
			>
				{Number(payload.value).toLocaleString()}
			</text>
		</g>
	);
}

function formatDateToYYYYMMDD(ndate: number) {
	const date = new Date(ndate);
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-11, so add 1
	const day = String(date.getDate()).padStart(2, "0"); // getDate() returns the day of the month

	return `${year}-${month}-${day}`;
}

function CustomizedXAxisTick(props: any) {
	const { x, y, stroke, payload } = props;
	return (
		<g transform={`translate(${x},${y})`}>
			<text
				x={0}
				y={0}
				dy={0}
				fill="white"
				transform="rotate(-0)"
				textAnchor="end"
				style={{ fontSize: "0.75rem" }}
			>
				{formatDateToYYYYMMDD(payload.value)}
			</text>
		</g>
	);
}

const CustomTooltip = (props: any) => {
	const { active, payload, label } = props;
	if (active && payload && payload.length) {
		const date = new Date(payload[0].payload.date);
		const formattedDate = `${weekdays[date.getDay()]}, ${
			months[date.getMonth()]
		} ${date.getDate()}`;
		return (
			<div className="p-3 text-sm text-dark bg-light ">
				<p className="pb-2 font-bold">{`${formattedDate}`}</p>
				{payload.map((item: any) => {
					return (
						<p>
							<span
								style={{
									color: stringToColor(item.dataKey, COLORS),
								}}
							>
								--
							</span>
							{` ${item.dataKey.replace("_", " ")}:     ${Number(
								item.value
							).toFixed(2)}`}
						</p>
					);
				})}
			</div>
		);
	}

	return null;
};

export default function MultiLineGraph({
	data,
	graphOptions,
	keys,
}: {
	data: MultiPlatformTimeSeries[] | undefined;
	graphOptions: GraphOptionsBase;
	keys: string[];
}) {
	const formattedData = data?.map((item) => {
		return { ...item, date: new Date(item.date).getTime() };
	});
	const filteredData = formattedData?.filter((item) => {
		let useful_data = false;
		for (let key in item) {
			if (keys.includes(key)) {
				useful_data = true;
			}
		}
		return useful_data;
	});

	const maxDate = formattedData?.reduce((acc, curr) => {
		if (curr.date > acc) {
			return curr.date;
		} else {
			return acc;
		}
	}, 0);
	const minDate = filteredData?.reduce(
		(acc, curr) => {
			if (curr.date < acc) {
				return curr.date;
			} else {
				return acc;
			}
		},
		maxDate ? maxDate : 0
	);

	return (
		data && (
			<ResponsiveContainer height="100%" width="100%">
				<LineChart
					data={filteredData}
					{...{
						overflow: "visible",
					}}
				>
					<XAxis
						dataKey="date"
						// scale="utc"
						type="number"
						style={{
							fontSize: "0.75rem",
							fill: "white",
						}}
						// @ts-ignore
						domain={[minDate, maxDate]}
						label={{
							value: graphOptions.independent,
							position: "insideBottomRight",
							offset: -5,
							fontSize: "0.75rem",
						}}
						tickFormatter={formatDateToYYYYMMDD}
					/>
					<YAxis
						type="number"
						height={100}
						width={30}
						tick={CustomizedYAxisTick}
						label={{
							value: graphOptions.dependent,
							position: "insideTopLeft",
							fontSize: "0.75rem",
							offset: -25,
						}}
					/>
					{keys.map((key) => {
						return (
							<Line
								dataKey={key}
								fill="#8884d8"
								isAnimationActive={false}
								style={{
									stroke: stringToColor(key, COLORS),
								}}
								dot={data.length < 15}
							/>
						);
					})}
					{/* <Tooltip /> */}
					<Tooltip content={<CustomTooltip />} />
				</LineChart>
			</ResponsiveContainer>
		)
	);
}
