import { useState, useEffect, MouseEventHandler } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { RevenueData, RevenueMetric } from "./types";
import MultiLineGraph from "./MultiLineGraph";
import CheckedCards from "../../../Components/CheckedCards";
import { fetchFilmOverallPerformance } from "../../../Services/films/films";
import DatePicker from "../../../Components/DatePicker";
import BiTab from "../../../Components/BiTab";

const defaultMetrics: RevenueMetric[] = [
	{
		title: "Total Net Revenue",
		quantity: undefined,
		key: "net",
		checked: true,
	},
	{
		title: "Film Ticket Revenue",
		quantity: undefined,
		key: "film",
		checked: false,
	},
	{
		title: "Event Ticket Revenue",
		quantity: undefined,
		key: "event",
		checked: false,
	},
];

export function RevenueSection({
	revenueData,
	getFilmOverallPerformance,
}: {
	revenueData: RevenueData | undefined;
	getFilmOverallPerformance: Function;
}) {
	const [notCumul, setNotCumul] = useState<boolean>(true);
	const [startDate, setStartDate] = useState<string>("");
	const [endDate, setEndDate] = useState<string>("");
	const [metrics, setMetrics] = useState<RevenueMetric[]>(defaultMetrics);

	const { getAccessTokenSilently } = useAuth0();
	const { filmId } = useParams();

	useEffect(() => {
		if (revenueData) {
			setMetrics([
				{
					title: "Total Net Revenue",
					quantity: revenueData.totals && revenueData.totals.totalNet,
					key: "total",
					checked: true,
					prefix: "$",
				},
				{
					title: "Film Ticket Revenue",
					quantity:
						revenueData.totals && revenueData.totals.filmTicket,
					key: "film_revenue",
					checked: false,
					prefix: "$",
				},
				{
					title: "Event Ticket Revenue",
					quantity:
						revenueData.totals && revenueData.totals.eventTicket,
					key: "event_revenue",
					checked: false,
					prefix: "$",
				},
			]);
		}
	}, [revenueData]);

	const handleApplyTimeBound = async () => {
		if (startDate && endDate) {
			getFilmOverallPerformance(new Date(startDate), new Date(endDate));
		}
	};

	return (
		<div className="w-full p-8 ">
			<div className="flex flex-row items-center justify-between w-full">
				<BiTab
					bool={notCumul}
					setBool={setNotCumul}
					labels={["DAY-BY-DAY", "CUMULATIVE"]}
				/>
				<DatePicker
					startDate={startDate}
					endDate={endDate}
					setStartDate={setStartDate}
					setEndDate={setEndDate}
					onApply={handleApplyTimeBound}
				/>
			</div>
			<div className="h-[50vh] bg-shade2 flex flex-col justify-between p-3">
				<div className="w-full h-[20%]">
					<CheckedCards metrics={metrics} setMetrics={setMetrics} />
				</div>
				<div className="w-full h-[65%]">
					{revenueData && (
						<MultiLineGraph
							data={
								notCumul
									? revenueData.timeSeries
									: revenueData.cumulTimeSeries
							}
							graphOptions={{}}
							keys={metrics.reduce<string[]>((acc, metric) => {
								if (metric.checked) {
									acc.push(metric.key);
								}
								return acc;
							}, [])}
						/>
					)}
				</div>
			</div>
		</div>
	);
}
