import { useState, useEffect } from "react";
import { NavLink, Navigate, useParams, Link } from "react-router-dom";
import { fetchFilmOverview } from "../../Services/films/films";

import { useAuth0 } from "@auth0/auth0-react";
import { FilmOverview } from "../../Pages/Films/types";
import {
	IconAdjustmentsHorizontal,
	IconArrowLeft,
	IconDeviceTv,
	IconFileAnalytics,
	IconSpeakerphone,
	IconTicket,
} from "@tabler/icons-react";
import { NavItem } from "../../types/types";
import { capitalizeWords } from "../../Utils/utils";

export default function InnerNavigation() {
	const { user, logout, isAuthenticated, isLoading, getAccessTokenSilently } =
		useAuth0();
	const { filmId } = useParams();
	const [filmData, setFilmData] = useState<FilmOverview>();

	function logoutHandler() {
		console.log("Logging out");
		logout({
			logoutParams: {
				returnTo: window.location.origin,
			},
		});
	}

	useEffect(() => {
		const getFilmData = async () => {
			const accessToken = await getAccessTokenSilently();
			const fetchedFilmData = await fetchFilmOverview(
				accessToken,
				filmId
			);
			setFilmData(fetchedFilmData);
		};
		getFilmData();
	}, []);

	const innerNav = [
		{
			path: `/films/${filmId}`,
			title: "Overall Performance",
			icon: <IconFileAnalytics />,
		},
		{
			path: `/films/${filmId}/marketing`,
			title: "Marketing Spend",
			icon: <IconSpeakerphone />,
		},
		{
			path: `/films/${filmId}/reach`,
			title: "Reach & Tactics",
			icon: <IconAdjustmentsHorizontal />,
		},
		{
			path: `/films/${filmId}/filmperformance`,
			title: "Film Performance",
			icon: <IconDeviceTv />,
		},
		{
			path: `/films/${filmId}/watchperformance`,
			title: "Watch Performance",
			icon: <IconTicket />,
		},
	];
	if (isLoading) {
		return <></>;
	}
	return (
		<>
			<div className="h-full w-full flex flex-col  bg-dark border-r text-right text-2xl py-2.5 p-8">
				<Link to="/films" className="p-3">
					<IconArrowLeft />
				</Link>
				<img
					className="w-full rounded-xl"
					src={`${process.env.PUBLIC_URL}/Images/logo_dark.png`}
					alt="logo"
				/>
				<div className="h-60% flex flex-col items-end justify-between">
					<div className="flex flex-col pt-4">
						{filmData && (
							<>
								<div className="relative w-full p-[2%] text-xl h-full self-end">
									<img
										className="object-contain w-full h-full rounded-sm"
										src={`${process.env.REACT_APP_CLOUDFRONT_DOMAIN}${filmData.img_path}`}
										alt="logo"
									/>
								</div>
								<div className="self-start mb-6">
									{capitalizeWords(filmData.title)}
								</div>
							</>
						)}
						{innerNav.map((item) => {
							return (
								<SideBarItem
									path={item.path}
									title={item.title}
									key={item.title}
									icon={item.icon}
								/>
							);
						})}
					</div>
				</div>
				<div className="flex flex-col gap-2 p-8 mt-auto ">
					{/* <SideBarItem path="" title="Settings" /> */}
					<button className="text-right " onClick={logoutHandler}>
						Logout
					</button>
				</div>
			</div>
		</>
	);
}

function SideBarItem({ path, title, icon }: NavItem) {
	return (
		<NavLink
			to={path}
			className={({ isActive }) =>
				isActive ? "text-accent1 font-semibold" : ""
			}
			end
		>
			<div className="flex flex-row items-center">
				<div className="mr-3">{icon}</div>
				<h2 className="py-2 text-base">{title}</h2>
			</div>
		</NavLink>
	);
}
